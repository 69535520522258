import React, { createContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import axios from "axios";

const CartContext = createContext();
export default CartContext;

export function CartProvider({ children }) {
  const UserRoles = {
    PRODUCTADMIN: 1,
    STOREADMIN: 2,
    STOREMANAGER: 3,
    ORDERS: 4,
    PACKAGING: 5,
    DELIVERY: 6,
    PAYMENT: 7,
    RETURNS: 8,
    SHIPPING: 9,
    CRMMANAGER: 10,
    CRMSALEEXECUTIVE: 11,
    PRODUCTPURCHASEMANAGER: 12,
    RAWMATERIALSINVENTORYMANAGER: 13,
    MANUFACTURINGMANAGER: 14,
    MANUFACTURINGEMPLOYEE: 15,
    MANUFACTURINGQUALITYCHECK: 16,
    MANUFACTURINGSHIPPING: 17,
    SERVICINGMANAGER: 18,
    SERVICINGBOOKING: 19,
    SERVICINGENGINEER: 20,
    SERVICINGQUALITYCHECK: 21,
    SERVICINGPAYMENT: 22,
    SERVICINGDELIVERY: 23,
    HRMANAGER: 24,
    HREMPLOYEE: 25,
    ACCOUNTINGMANAGER: 26,
    ACCOUNTINGEMPLOYEE: 27,
    QUOTATIONMANAGER: 28,
    QUOTATIONEMPLOYEE: 29,
  };
  const DeliveryMethods = {
    INSTORE: 1,
    TAKEAWAY: 2,
    HOMEDELIVERY: 3,
    SWIGGY: 4,
    ZOMATO: 5,
  };
  const PaymentMethods = {
    CashOnDelivery: 1,
    RazorPay: 2,
    Cash: 3,
    QrCode: 4,
    CardSwipeMachine: 5,
    PayWithInvoice: 6,
    Swiggy: 7,
    Zomatto: 8,
    BankReceipt: 9,
  };

  const PaymentStates = {
    PAID: 1,
    UNPAID: 2,
  };

  const OrderStates = {
    RECEIVED: 1,
    STARTPACKAGING: 2,
    CANCELLED: 3,
    PACKAGED: 4,
    SHORTAGEITEMS: 5,
    SENTDELIVERY: 6,
    DELIVEREDANDPAID: 7,
    DELIVEREDBUTUNPAID: 8,
    PAYMENTRECEIVED: 9,
    REFUNDED: 10,
    CLOSED: 11,
    RETURNED: 12,
    ADDTOINVENTORY: 13,
    ADDEDTOINVENTORY: 14,
    CANCELPACKAGE: 15,
    CANCELDELIVERY: 16,
    CANCELLEDREFUNDNOTREQUIRED: 17,
    CANCELANDCLOSE: 18,
    REFUNDEDANDCLOSED: 19,
    RETURNEHANDEDDANDREFUNDED: 20,
  };

  const REACT_APP_VERSION = "23.10.02";
  const IsDev = false;
  const [returantTables, setReturantTables] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [orderSummary, setOrderSummary] = useState("");
  const [storeSettings, setStoreSettings] = useState("");
  const [myCart, setMyCart] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [AddedToCartMsg, setAddedToCartMsg] = useState("");
  const [OrderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [storeId, setStoreId] = useState("");
  const [posRoles, setPosRoles] = useState([]);
  const [guestUserTypeId, setGuestUserTypeId] = useState("2");
  const [rawMaterialsInventory, setRawMaterialsInventory] = useState([]);
  const [manufacturingStatusTypes, setManufacturingStatusTypes] = useState([]);
  const [productServiceStatusTypes, setProductServiceStatusTypes] = useState(
    []
  );
  const [clientCertificates, setClientCertificates] = useState([]);
  const [clientLogos, setClientLogos] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState([]);
  const [vlogs, setVlogs] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const [productServiceTasksInventory, setProductServiceTasksInventory] =
    useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [taxDetails, settaxDetails] = useState({});
  const [brands, setBrands] = useState([]);
  const [themes, setThemes] = useState([]);
  const [theme, setTheme] = useState("");
  const [productThemes, setProductThemes] = useState([]);
  const [store, setStore] = useState([]);
  const [images, setImages] = useState([]);

  const [productUnitTypes, setProductUnitTypes] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [productGroupsAndProducts, setProductGroupsAndProducts] = useState([]);
  const [prods, setProducts] = useState([]);
  const [prodsAll, setProductsAll] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [mainCarousel, setMainCarousel] = useState([]);
  const [mainMenus, setMainMenus] = useState([]);
  const [top5Ads, setTop5Ads] = useState([]);
  const [storeRoles, setStoreRoles] = useState([]);
  const [orderStatusTypes, setOrderStatusTypes] = useState([]);
  const [customerOrderPaymentStatusTypes, setCustomerOrderPaymentStatusTypes] =
    useState([]);
  const [restaurantTableOrdereStatus, setRestaurantTableOrdereStatus] =
    useState([]);

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    emailid: "",
    bearertoken: "",
    loginStatus: "inactive", //active/inactive
    hideSignupLink: "", //set "true" for hide; set "" for show
  });

  const enableOrderToolbarIcon = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.ORDERS == userData.roleId ||
      UserRoles.CRMMANAGER == userData.roleId ||
      UserRoles.CRMSALEEXECUTIVE == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };

  const enableQuotationToolbarIcon = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.QUOTATIONMANAGER == userData.roleId ||
      UserRoles.QUOTATIONEMPLOYEE == userData.roleId ||
      UserRoles.CRMMANAGER == userData.roleId ||
      UserRoles.CRMSALEEXECUTIVE == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };

  const enablePackaingToolbarIcon = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.ORDERS == userData.roleId ||
      UserRoles.PACKAGING == userData.roleId ||
      UserRoles.CRMMANAGER == userData.roleId ||
      UserRoles.CRMSALEEXECUTIVE == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  const enableShippingToolbarIcon = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.ORDERS == userData.roleId ||
      UserRoles.SHIPPING == userData.roleId ||
      UserRoles.CRMMANAGER == userData.roleId ||
      UserRoles.CRMSALEEXECUTIVE == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  const enableDeliveryToolbarIcon = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.ORDERS == userData.roleId ||
      UserRoles.DELIVERY == userData.roleId ||
      UserRoles.CRMMANAGER == userData.roleId ||
      UserRoles.CRMSALEEXECUTIVE == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  const enablePaymentToolbarIcon = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.ORDERS == userData.roleId ||
      UserRoles.PAYMENT == userData.roleId ||
      UserRoles.CRMMANAGER == userData.roleId ||
      UserRoles.CRMSALEEXECUTIVE == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };

  const enableProductPurchases = () => {
    if (
      UserRoles.PRODUCTADMIN == userData.roleId ||
      UserRoles.STOREADMIN == userData.roleId ||
      UserRoles.STOREMANAGER == userData.roleId ||
      UserRoles.PRODUCTPURCHASEMANAGER == userData.roleId ||
      UserRoles.RAWMATERIALSINVENTORYMANAGER == userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };

  const enableRawMaterials = () => {
    if (
      (UserRoles.PRODUCTADMIN == userData.roleId ||
        UserRoles.STOREADMIN == userData.roleId ||
        UserRoles.STOREMANAGER == userData.roleId ||
        UserRoles.PRODUCTPURCHASEMANAGER == userData.roleId ||
        UserRoles.RAWMATERIALSINVENTORYMANAGER == userData.roleId) == true
    ) {
      return false;
    } else {
      return true;
    }
  };
  const enableEmployees = () => {
    if (
      (UserRoles.PRODUCTADMIN == userData.roleId ||
        UserRoles.STOREADMIN == userData.roleId ||
        UserRoles.STOREMANAGER == userData.roleId ||
        UserRoles.HRMANAGER == userData.roleId ||
        UserRoles.HREMPLOYEE == userData.roleId) == true
    ) {
      return false;
    } else {
      return true;
    }
  };
  const enableAccounting = () => {
    if (
      (UserRoles.PRODUCTADMIN == userData.roleId ||
        UserRoles.STOREADMIN == userData.roleId ||
        UserRoles.STOREMANAGER == userData.roleId ||
        UserRoles.ACCOUNTINGMANAGER == userData.roleId ||
        UserRoles.ACCOUNTINGEMPLOYEE == userData.roleId) == true
    ) {
      return false;
    } else {
      return true;
    }
  };

  const enableDeleteButtonMenu = () => {
    if (UserRoles.PRODUCTADMIN == userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  const GetRawMaterialsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setRawMaterialsInventory(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const GetClientCertificates = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientCertificates/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setClientCertificates(
            data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          );
        }
      })
      .catch((error) => {});
  };

  const GetClientLogos = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetClientLogos/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setClientLogos(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };

  const GetClientTestimonials = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientTestimonials/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setClientTestimonials(
            data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          );
        }
      })
      .catch((error) => {});
  };
  const GetVlogs = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "Consumers/GetVlogs/" + storeId)
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          setVlogs(response.data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
      });
  };
  const GetBlogs = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "Consumers/GetBlogs/" + storeId)
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          setBlogs(response.data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
      });
  };

  const GetProductServiceTasksInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceTasksInventories/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setProductServiceTasksInventory(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const [guestUserData, setGuestUserData] = useState([]);
  const StoreGuestUserData = (data) => {
    setGuestUserData(data);
  };

  const GetDefaultCurrency = () => {
    if (storeSettings.defaultCurrency == "INR") {
      return "₹";
    } else if (storeSettings.defaultCurrency == "USD") {
      return "$";
    }
  };

  const GetRestaturantTables = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetRestaturantTables/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setReturantTables(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      });
  };
  const storeOrderInformation = (data) => {
    setOrderData(data);
  };
  const UpdateOrderStatus = (status) => {
    setOrderStatus(status);
  };

  const UpdateSelectedMenuItem = (menuitem) => {
    setSelectedMenuItem(menuitem);
  };

  const GetCustomerOrderPaymentStatusTypes = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetCustomerOrderPaymentStatusTypes/"
    )
      .then((response) => response.json())
      .then((data) => {
        setCustomerOrderPaymentStatusTypes(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetCustomerOrderPaymentStatusTypes fetch err:" + error);
      });
  };

  const getTaxDetails = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetTaxDetails/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          settaxDetails(data);
        }
      })
      .catch((error) => {
        console.error("GetShippingDetails!", error);
      });
  };

  const GetOrdersSummary = () => {
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetOrdersSummary/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        setOrderSummary(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrdersSummary fetch err:" + error);
      });
  };

  const GetAllImageNames = () => {
    fetch(process.env.REACT_APP_API + "Common/GetAllImageNames/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setImages(data.sort((a, b) => (a.size < b.size ? 1 : -1)));
        }
      })
      .catch((error) => {
        // alert("GetAllImageNames:: " + error);
      });
  };

  const GetManufacturingStatusTypes = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetManufacturingStatusTypes/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setManufacturingStatusTypes(data);
      })
      .catch((error) => {
        // alert("GetProductGroups:: " + error);
      });
  };
  const GetProductServiceStatusTypes = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceStatusTypes/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setProductServiceStatusTypes(data);
      })
      .catch((error) => {
        // alert("GetProductGroups:: " + error);
      });
  };

  const GetProductGroups = () => {
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetProductGroups/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setProductGroups(data);
      })
      .catch((error) => {
        // alert("GetProductGroups:: " + error);
      });
  };

  const GetOrderStatusTypes = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "StoreManager/GetOrderStatusTypes/")
      .then((response) => {
        if (response.status === 200) {
          setOrderStatusTypes(response.data);
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };

  const GetMainMenus = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainMenus/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setMainMenus(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };
  const GetTop5Ads = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetTop5Ads/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTop5Ads(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };

  const GetStoreRoles = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetStoreUserRoles/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setStoreRoles(data);
      })
      .catch((error) => {
        // alert("GetStoreRoles:: " + error);
      });
  };

  const GetMainCarousels = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "Consumers/GetAllCarousels/" + storeId)
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          setCarousels(
            response.data
              .sort((a, b) => (a.seqNo < b.seqNo ? 1 : -1))
              .sort((a, b) => (a.targetDevice > b.targetDevice ? 1 : -1))
          );
        } else {
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
        // alert("Error GetCarousel:" + error);
      });
  };

  const searchOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["title", "author.firstName"],
  };

  const getImageList = () => {
    return [
      { fileName: "" },
      { fileName: "amla.png" },
      { fileName: "Banganapalli.png" },
      { fileName: "chikku.jpg" },
      { fileName: "GuavaAlahabad.png" },
      { fileName: "GuavaTaiwan.png" },
      { fileName: "Malika.png" },
      { fileName: "GuavaTaiwan.png" },
      { fileName: "Malika.png" },
      { fileName: "Banganapalli.png" },
      { fileName: "chikku.jpg" },
      { fileName: "GuavaAlahabad.png" },
      { fileName: "GuavaTaiwan.png" },
      { fileName: "Malika.png" },
      { fileName: "Karpuravallibanana.jpg" },
      { fileName: "redbanana.png" },
      { fileName: "redlady.jpg" },
      { fileName: "BananaFlower.png" },
      { fileName: "BigOnion.png" },
      { fileName: "bitter.jpg" },
      { fileName: "beet1.png" },
      { fileName: "Broccoli.png" },
      { fileName: "cucumber.png" },
      { fileName: "GarlicNati.png" },
      { fileName: "Maravalli.png" },
      { fileName: "GreenChilli.png" },
      { fileName: "okra.jpg" },
      { fileName: "AmulCheese.jpg" },
      { fileName: "AmulGoldMilk.jpg" },
      { fileName: "AmulkMilk.jpg" },
      { fileName: "MilkyMistCurdContainer.jpg" },
      { fileName: "NandhiniMilk.jpg" },
      { fileName: "MilkyMistCheese.jpg" },
      { fileName: "IdlyPlates.jpg" },
      { fileName: "KichenRack.jpg" },
      { fileName: "SpoonContainerSteel.jpg" },
      { fileName: "SpoonContainerWood.jpg" },
      { fileName: "SteelTool.jpg" },
      { fileName: "VegCutter.jpg" },
      { fileName: "WoodenTools.jpg" },
      { fileName: "SnackCheetos.jpg" },
      { fileName: "SnackKodubale.jpg" },
      { fileName: "SnackMarieGold.jpg" },
      { fileName: "SnackMoongDal.jpg" },
      { fileName: "SnackRagiStick.jpg" },
      { fileName: "SnacPuffcorn.jpg" },
      { fileName: "AmulChocolate.jpg" },
      { fileName: "AmulCone.jpg" },
      { fileName: "AmulFruitNuts.jpg" },
      { fileName: "KuwalityMango.jpg" },
      { fileName: "ShahiAnjir.jpg" },
      { fileName: "Ashirwad.jpg" },
      { fileName: "Idayam.jpg" },
      { fileName: "Parle.jpg" },
      { fileName: "Amul.jpg" },
      { fileName: "Milky-Mist.jpg" },
    ];
  };

  const GetSearchResult = (pattenText) => {
    //setSearchData(options);
    const fuse = new Fuse(prods, searchOptions);
    setSearchData(fuse.search(pattenText));
  };

  const storePreviousPage = (value) => {
    setPrevPage(value);
  };

  const ClearCartAndOrderDetails = () => {
    setMyCart([{}]);
    localStorage.removeItem("localstoragecartitems");
    //setOrderData("");
  };

  const GetStore = () => {
    fetch(process.env.REACT_APP_API + "Sales/GetStore/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setStore(data);
          document.title = data.name + "- Admin";
        }
      })
      .catch((error) => {
        // alert("GetStores Error:: " + error);
      });
  };

  const GetStoreSettings = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetStoreSetting/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setStoreSettings(data);
          if (data.selectedThemeType == "Product") {
            getProductTheme(data.themeName);
          } else if (data.selectedThemeType == "Custom") {
            getCustomTheme(data.customThemeName);
          }
        }
      })
      .catch((error) => {
        // this.ShowMessagBox("Warning", "Session Timeout!");
      });
  };
  const getProductTheme = (themeName) => {
    if (themeName == "") {
      alert("ThemeName is not set!");
      return;
    }
    fetch(process.env.REACT_APP_API + "Consumers/GetProductTheme/" + themeName)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTheme(data);
        }
      })
      .catch((error) => {
        console.error("GetTheme: There was an error!", error);
      });
  };
  const getCustomTheme = (themeName) => {
    if (themeName == "") {
      alert("ThemeName is not set!");
      return;
    }
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCustomTheme/" +
        storeId +
        "," +
        themeName
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTheme(data);
        }
      })
      .catch((error) => {
        console.error("GetTheme: There was an error!", error);
      });
  };

  const storeUserData = (data) => {
    setUserData({
      id: data.id,
      // firstname: data.firstName,
      // lastname: data.lastName,
      emailId: data.emailId,
      password: data.password,
      roleId: data.roleId,
      lastSignIn: data.lastSignIn,
      bearertoken: data.bearerToken,
      loginStatus: "active",
      hideSignupLink: "true", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      return state;
    });
  };
  const clearUserData = () => {
    setUserData({
      emailid: "",
      password: "",
      bearertoken: "",
      loginStatus: "inactive",
      hideSignupLink: "", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      return state;
    });
  };

  const RefreshBearerToken = (emailid, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: 0,
        firstName: "string",
        lastName: "string",
        mobileNo: "string",
        emailId: emailid,
        password: password,
        photoUrl: "string",
        address: "string",
        landmark: "string",
        city: "string",
        state: "string",
        country: "string",
        pinCode: "string",
        createdDateTime: "2022-06-13T12:34:21.806Z",
        modifiedDateTime: "2022-06-13T12:34:21.806Z",
      }),
    };

    fetch(process.env.REACT_APP_API + "Consumers/Login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          return;
        }
        storeUserData(data);
        console.log("storeUserData(data) success");
      })
      .catch((error) => {
        console.error("RefreshBearerToken: There was an error!", error);
        //alert("RefreshBearerToken err:" + error);
      });
  };
  const getMainCategories = () => {
    setMainCategories([]);
    fetch(process.env.REACT_APP_API + "StoreManager/GetMainCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setMainCategories(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("getMainCategories err:" + error);
      });
  };
  const GetBasePrice = (prod) => {
    return Number(prod.mrp) - (Number(prod.mrp) * Number(prod.discount)) / 100;
  };
  const GetTakeAwayPrice = (prod) => {
    return (
      Number(prod.mrp) +
      (Number(prod.mrp) * Number(prod.takeAwayChargeInPercentage)) / 100 -
      ((Number(prod.mrp) +
        (Number(prod.mrp) * Number(prod.takeAwayChargeInPercentage)) / 100) *
        Number(prod.discount)) /
        100
    );
  };
  const GetHomeDeliveryPrice = (prod) => {
    return (
      Number(prod.mrp) +
      (Number(prod.mrp) * Number(prod.homeDeliveryChargeInPercentage)) / 100 -
      ((Number(prod.mrp) +
        (Number(prod.mrp) * Number(prod.homeDeliveryChargeInPercentage)) /
          100) *
        Number(prod.discount)) /
        100
    );
  };

  const getMainCategoryName = (mainCategoryId) => {
    var name = "";
    mainCategories.map((main) => {
      if (main.id == mainCategoryId) {
        console.log(main.name);
        name = main.name;
        return name;
      }
    });
    return name;
  };

  const GetPosUserRoles = () => {
    fetch(process.env.REACT_APP_API + "Common/GetPosUserRoles/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setPosRoles(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getSubCategories = () => {
    setSubCategories([]);
    fetch(process.env.REACT_APP_API + "StoreManager/GetSubCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setSubCategories(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("getSubCategories err:" + error);
      });
  };

  const getBrands = () => {
    setBrands([]);
    fetch(process.env.REACT_APP_API + "StoreManager/GetBrands/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setBrands(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const getThemes = () => {
    setThemes([]);
    fetch(process.env.REACT_APP_API + "StoreManager/GetThemes/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setThemes(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("Error GetThemes");
      });
  };
  const getProductThemes = () => {
    setProductThemes([]);
    fetch(process.env.REACT_APP_API + "StoreManager/GetProductThemes/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setProductThemes(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("Error GetProductThemes");
      });
  };

  const GetProductUnitTypes = () => {
    fetch(process.env.REACT_APP_API + "Common/GetProductUnitTypes")
      .then((response) => response.json())
      .then((data) => {
        setProductUnitTypes(data);
      })
      .catch((error) => {
        alert("GetProductUnitTypes Err:" + error);
        // console.error("There was an error!", error);
      });
  };
  const GetAllProducts = () => {
    var querystring = `?MainCategoryId=-1&SubCategoryId=-1&filtertext=-1`;
    fetch(process.env.REACT_APP_API + "StoreManager/GetProducts/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          if (data.length > 0) {
            var sortedProducts = data.sort((a, b) =>
              a.seqNo > b.seqNo ? 1 : -1
            );
            setProducts(sortedProducts);
            setProductsAll(sortedProducts);
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const DeleteProductFromMemory = (productId) => {
    setProducts(prods.filter((productitem) => productId !== productitem.id));
    setProductsAll(
      prodsAll.filter((productitem) => productId !== productitem.id)
    );
  };

  const UpdateProductInContext = (product) => {
    for (var i = 0; i < prods.length; i++) {
      if (prods[i].id == product.id) {
        prods[i] = product;
        break;
      }
    }

    for (var i = 0; i < prodsAll.length; i++) {
      if (prodsAll[i].id == product.id) {
        prodsAll[i] = product;
        break;
      }
    }
  };

  const GetProductsByMainCategoryId = (MainCategoryId) => {
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter(
        (productitem) =>
          MainCategoryId == productitem.subCategory.mainCategoryId
      )
    );
  };
  const GetProductsBySubCategoryId = (CategoryId) => {
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter((productitem) => CategoryId == productitem.subCategoryId)
    );
  };

  //create your forceUpdate hook
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  }
  const forceUpdateCartView = useForceUpdate();

  const addToMyCart = (cartitem) => {
    //add if first item
    if (myCart === "") {
      setMyCart([...myCart, cartitem]);
      setAddedToCartMsg("Added To Cart");
      return;
    }
    //check if product items exist, go for update
    const indexItem = myCart.findIndex(
      (item) => item.prod.id == cartitem.prod.id
    );
    if (indexItem != -1) {
      //update
      myCart.map((item) => {
        if (item.prod.id == cartitem.prod.id) {
          // item.qty = Number(item.qty) + Number(cartitem.qty);
          setAddedToCartMsg("Already Added");
        }
      });
    } else {
      // add
      setMyCart([...myCart, cartitem]);
      setAddedToCartMsg("Added To Cart");
    }

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const deleteItemOnMyCart = (id) => {
    setMyCart(myCart.filter((productitem) => id !== productitem.id));

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const ClearCookies = () => {
    //ClearCookies
    localStorage.setItem("localstoragecartitems", "");
    localStorage.setItem("localstorageUserData", "");
  };

  //when page is reloaded, restore the cart from cookie
  //todo: clear the cookie when localstorage is older than a week/month
  useEffect(() => {
    //use when required
    //this.ClearCookies();
    // //todo: enable
    // let v1 = localStorage.getItem("localstoragecartitems");
    // if (v1 !== null && v1 !== `""` && v1 !== ``) setMyCart(JSON.parse(v1));
    // v1 = localStorage.getItem("localstorageUserData");
    // if (v1 !== null && v1 !== `""` && v1 !== ``) setUserData(JSON.parse(v1));
  }, []);

  const ClearAllCookies = () => {
    //1. clear Cart Items
    localStorage.setItem("localstoragecartitems", null);
  };

  const getTotalSavingsFromCart = (myCart) => {
    var totsav = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totsav =
          totsav +
          (Number(item.prod.mrp) *
            Number(item.qty) *
            Number(item.prod.discount)) /
            100;
      });
    }
    return Number(totsav);
  };

  const getSubTotalPriceInCart = (myCart) => {
    var totprice = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totprice =
          totprice +
          Number(item.qty) *
            (Number(item.prod.mrp) -
              (Number(item.prod.mrp) * Number(item.prod.discount)) / 100);
      });
    }
    return Number(totprice);
  };
  const getTotalQtyInCart = (myCart) => {
    var totqty = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totqty = totqty + Number(item.qty);
      });
    }
    return totqty;
  };
  const getTotalPriceInCart = (myCart) => {
    var totprice = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totprice = totprice + Number(item.qty) * Number(item.price);
      });
    }
    return totprice;
  };

  const GetRestaurantTableOrdereStatus = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetRestaurantTableOrdereStatus/"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setRestaurantTableOrdereStatus(data);
        }
      });
  };
  const GetRestaurantTableOrdereStatusById = (statusID) => {
    return restaurantTableOrdereStatus.filter((f) => f.id == statusID)[0];
  };

  const ReturnwithDiscount = (mrp, qty, discount) => {
    return mrp * qty - (mrp * qty * discount) / 100;
  };
  const ReturnwithTax = (mrp, qty, discount, tax) => {
    return (
      ReturnwithDiscount(mrp, qty, discount) +
      (ReturnwithDiscount(mrp, qty, discount) * tax) / 100
    );
  };

  const GetTicks = (date) => {
    var currentTime = date.getTime();
    // 10,000 ticks in 1 millisecond
    // jsTicks is number of ticks from midnight Jan 1, 1970
    var jsTicks = currentTime * 10000;
    // add 621355968000000000 to jsTicks
    // netTicks is number of ticks from midnight Jan 1, 01 CE
    var netTicks = jsTicks + 621355968000000000;
    return netTicks;
  };

  const GetInDateFormat = (D) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = D.getDate();

    const monthIndex = D.getMonth();
    const monthName = monthNames[monthIndex];

    const year = D.getFullYear();

    return `${day}-${monthName}-${year}`;
  };
  const GetDateResult = (dateRange, startDate, endDate) => {
    var reportDetails = "";
    var today = new Date();
    var yday = new Date(Date.now() - 864e5);
    var threeday = new Date(Date.now() - 864e5 * 2);
    var sevenday = new Date(Date.now() - 864e5 * 6);
    if (dateRange == "Today")
      reportDetails += "Date:," + GetInDateFormat(new Date());
    if (dateRange == "Yesterday")
      reportDetails += "Date:," + GetInDateFormat(yday);
    if (dateRange == "Last 3 Days")
      reportDetails +=
        "Date Between:," +
        GetInDateFormat(threeday) +
        "," +
        GetInDateFormat(new Date());
    if (dateRange == "Last 7 Days") {
      reportDetails +=
        "Date Between:," +
        GetInDateFormat(sevenday) +
        "," +
        GetInDateFormat(new Date());
    }
    if (dateRange == "This Month") {
      reportDetails +=
        "Date:,Month of " +
        new Date().toLocaleString("default", { month: "long" });
    }
    if (dateRange == "Last Month") {
      var tdate = new Date(Date.now());
      tdate.setMonth(today.getMonth() - 1);
      let previousMonth = tdate.toLocaleString("en-US", {
        month: "long",
      });
      reportDetails += "Date: Month of," + previousMonth;
    }
    if (dateRange == "Last 3 Months") {
      var tdate = new Date(Date.now());
      tdate.setMonth(today.getMonth() - 2);
      let previousMonth = tdate.toLocaleString("en-US", {
        month: "long",
      });
      reportDetails += "Date: Month From," + previousMonth;
    }
    if (dateRange == "All") {
      reportDetails += "Date till:," + GetInDateFormat(new Date());
    }
    if (dateRange == "Custom") {
      reportDetails +=
        "Date Between:," +
        GetInDateFormat(startDate) +
        "," +
        GetInDateFormat(endDate);
    }
    return reportDetails;
  };

  const updateMyCart = (cartitem) => {};

  return (
    <CartContext.Provider
      value={{
        REACT_APP_VERSION,
        IsDev,
        store,
        images,
        storeId,
        storeSettings,
        guestUserTypeId,
        searchData,
        myCart,
        mainCategories,
        subCategories,
        posRoles,
        brands,
        theme,
        themes,
        productThemes,
        productUnitTypes,
        prods,
        productGroups,
        productGroupsAndProducts,
        userData,
        paymentStatus,
        AddedToCartMsg,
        prevPage,
        orderData,
        OrderStatus,
        guestUserData,
        mainMenus,
        top5Ads,
        mainCarousel,
        orderStatusTypes,
        carousels,
        storeRoles,
        selectedMenuItem,
        orderSummary,
        customerOrderPaymentStatusTypes,
        taxDetails,
        UserRoles,
        returantTables,
        restaurantTableOrdereStatus,
        OrderStates,
        manufacturingStatusTypes,
        productServiceStatusTypes,
        rawMaterialsInventory,
        productServiceTasksInventory,
        PaymentStates,
        DeliveryMethods,
        PaymentMethods,
        clientCertificates,
        clientLogos,
        clientTestimonials,
        vlogs,
        blogs,
        setStoreSettings,
        GetStore,
        RefreshBearerToken,
        storeUserData,
        clearUserData,
        addToMyCart,
        deleteItemOnMyCart,
        forceUpdateCartView,
        GetStoreSettings,
        getTotalQtyInCart,
        getTotalPriceInCart,
        GetPosUserRoles,
        getMainCategories,
        getSubCategories,
        getBrands,
        getThemes,
        getProductThemes,
        GetAllProducts,
        GetProductsByMainCategoryId,
        GetProductsBySubCategoryId,
        GetSearchResult,
        GetProductUnitTypes,
        ClearCartAndOrderDetails,
        storePreviousPage,
        StoreGuestUserData,
        UpdateOrderStatus,
        storeOrderInformation,
        getMainCategoryName,
        getImageList,
        GetMainMenus,
        GetTop5Ads,
        GetOrderStatusTypes,
        GetProductGroups,
        setStoreId,
        DeleteProductFromMemory,
        UpdateProductInContext,
        GetAllImageNames,
        GetMainCarousels,
        GetStoreRoles,
        UpdateSelectedMenuItem,
        GetOrdersSummary,
        GetCustomerOrderPaymentStatusTypes,
        getTaxDetails,
        enableOrderToolbarIcon,
        enableQuotationToolbarIcon,
        enablePackaingToolbarIcon,
        enableShippingToolbarIcon,
        enableDeliveryToolbarIcon,
        enablePaymentToolbarIcon,
        enableProductPurchases,
        enableRawMaterials,
        enableEmployees,
        enableAccounting,
        GetDefaultCurrency,
        getSubTotalPriceInCart,
        getTotalSavingsFromCart,
        GetRestaturantTables,
        GetRestaurantTableOrdereStatus,
        GetRestaurantTableOrdereStatusById,
        GetBasePrice,
        GetTakeAwayPrice,
        GetHomeDeliveryPrice,
        enableDeleteButtonMenu,
        ReturnwithDiscount,
        ReturnwithTax,
        GetTicks,
        GetInDateFormat,
        GetDateResult,
        GetManufacturingStatusTypes,
        GetRawMaterialsInventories,
        GetProductServiceStatusTypes,
        GetProductServiceTasksInventories,
        GetClientCertificates,
        GetClientLogos,
        GetClientTestimonials,
        GetVlogs,
        GetBlogs,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

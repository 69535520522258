import React, { Component, useState } from "react";
import { SiHackthebox } from "react-icons/si";
import { TbReportAnalytics } from "react-icons/tb";
import { TbPackage } from "react-icons/tb";
import { RiFeedbackLine, RiCoupon5Line } from "react-icons/ri";
import { TbReceiptTax, TbBuildingStore } from "react-icons/tb";
import { BsFillCartCheckFill } from "react-icons/bs";
import { TiCloudStorage } from "react-icons/ti";
import { SiDatabricks } from "react-icons/si";

import CartContext from "./CartContext";
import { Accordion, Nav, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import UserRoles from "./CartContext";

import {
  BsUiChecksGrid,
  BsCartX,
  BsCardList,
  BsUiRadiosGrid,
  BsPeopleFill,
  BsBoundingBox,
  BsFillCameraFill,
  BsFillFileImageFill,
  BsFillLaptopFill,
  BsDiagram3,
  BsPersonSquare,
  BsAward,
  BsTruck,
  BsMenuDown,
  BsWallet2,
  BsPersonCheckFill,
  BsZoomOut,
} from "react-icons/bs";

export class SideMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productDefaultActiveKey: "1",
      customerDefaultActiveKey: "1",
      marketingDefaultActiveKey: "1",
    };
  }

  componentDidMount() {
    console.log("called");
  }

  enableProductsMenu = () => {
    if (
      this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREMANAGER == this.context.userData.roleId ||
      this.context.UserRoles.CRMSALEEXECUTIVE == this.context.userData.roleId ||
      this.context.UserRoles.CRMMANAGER == this.context.userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  enableCustomersMenu = () => {
    if (
      this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREMANAGER == this.context.userData.roleId ||
      this.context.UserRoles.ORDERS == this.context.userData.roleId ||
      this.context.UserRoles.CRMMANAGER == this.context.userData.roleId ||
      this.context.UserRoles.CRMSALEEXECUTIVE == this.context.userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  enablePortalSettingsMenu = () => {
    if (
      this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREMANAGER == this.context.userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  enableAdminMenu = () => {
    if (
      this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREADMIN == this.context.userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  enableProductAdminMenu = () => {
    if (this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div
        className="bg-white overflow-scroll "
        style={{ overflowY: "scroll", height: window.innerHeight * 2 + "px" }}
      >
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "11px",
              fontFamily: "tahoma",
              fontWeight: "bold",
            }}
            className="w-100 py-0 mx-0 my-0 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
            onClick={(e) => {
              this.context.selectedMenuItem = "dashboard";
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;Dashboard
          </Nav.Link>
        </ListGroup.Item>

        {/* Product Inventory */}
        {this.context.storeSettings.showProductInventoryModule == true ||
        this.context.storeSettings.showProductInventoryModule == undefined ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 m-0 "
              hidden={this.enableProductsMenu()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "product" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    e.eventKey = "1";
                    this.context.selectedMenuItem = "product";
                    //this.context.UpdateSelectedMenuItem("product");
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      as={Link}
                      className="m-0 p-0 p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                      onClick={(e) =>
                        this.setState({
                          productDefaultActiveKey: 0,
                        })
                      }
                    >
                      <BsCardList size="20" />
                      &nbsp;Product Inventory
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush" className="bg-black">
                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                          to="/ProductsListView"
                        >
                          <BsUiChecksGrid size="20" />
                          &nbsp;Product Inventory
                        </Nav.Link>
                      </ListGroup.Item>
                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                          to="/OutOfStock"
                          onClick={(e) =>
                            this.setState({
                              productDefaultActiveKey: 1,
                            })
                          }
                        >
                          <BsZoomOut size="20" />
                          &nbsp;Out-Of-Stock
                        </Nav.Link>
                      </ListGroup.Item>
                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className=" menubutton w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                          to="/Category"
                        >
                          <BsDiagram3 size="20" />
                          &nbsp;Category
                        </Nav.Link>
                      </ListGroup.Item>
                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                          to="/DataTypes"
                        >
                          <SiDatabricks size="20" />
                          &nbsp;DataTypes
                        </Nav.Link>
                      </ListGroup.Item>
                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                          to="/ProductGroups"
                        >
                          <BsBoundingBox size="20" />
                          &nbsp;Groups
                        </Nav.Link>
                      </ListGroup.Item>
                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                          to="/Brands"
                        >
                          <BsAward size="20" />
                          &nbsp;Brands
                        </Nav.Link>
                      </ListGroup.Item>

                      <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                          to="/BarCodeCustom"
                        >
                          <BsFillCameraFill size="20" />
                          &nbsp;BarCodes
                        </Nav.Link>
                      </ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* Product purchase */}
        {this.context.storeSettings.showPurchaseModule == true ||
        this.context.storeSettings.showPurchaseModule == undefined ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.context.enableProductPurchases()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "purchase" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "purchase";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      {/* <BsFillCartCheckFill size="20" /> */}
                      <TbPackage size="20"></TbPackage>
                      &nbsp;Product Purchase
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Suppliers"
                    >
                      <BsPeopleFill size="20" />
                      &nbsp;Supplier
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/PurchaseRequests"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Purchase Requests (PR)
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/PurchaseOrders"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Purchase Orders (PO)
                    </Nav.Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* RawMaterials */}
        {this.context.storeSettings.isManufacturer == true ||
        this.context.storeSettings.isServiceManagementBusiness == true ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.context.enableRawMaterials()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "kitchen" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "kitchen";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      as={Link}
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      <TbPackage size="20"></TbPackage>
                      {this.context.store.businessTypeId == 3 ? (
                        <>&nbsp;RawMaterials </>
                      ) : (
                        <>&nbsp;RawMaterials </>
                      )}
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/RawMaterialsInventory"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;RawMaterials Inventory
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/RawMaterialSuppliers"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;RawMaterial Suppliers
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/RawMaterialPurchaseRequests"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;RawMaterial Purchase Request (PR)
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/RawMaterialPurchaseOrders"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;RawMaterial Purchase Orders (PO)
                    </Nav.Link>
                    {this.context.store.businessTypeId == 3 ? (
                      <></>
                    ) : (
                      <>
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                          to="/RawMaterialManufacturingRequest"
                        >
                          <RiCoupon5Line size="20" />
                          &nbsp;Manufacturing Requests
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                          to="/RawMaterialServiceRequest"
                        >
                          <RiCoupon5Line size="20" />
                          &nbsp;Servicing Requests
                        </Nav.Link>
                      </>
                    )}

                    {/* <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/IngredientsInventory"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Ingredients Inventory
                    </Nav.Link> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* Manufacture */}
        {this.context.storeSettings.isManufacturer == true ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.enablePortalSettingsMenu()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "manufacture" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "manufacture";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      {/* <BsFillCartCheckFill size="20" /> */}
                      <TbPackage size="20"></TbPackage>
                      {this.context.store.businessTypeId == 3 ? (
                        <>&nbsp;Kitchen </>
                      ) : (
                        <> &nbsp;Manufacturing </>
                      )}
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body className="text-secondary">
                    {this.context.store.businessTypeId == 3 ? (
                      <></>
                    ) : (
                      <>
                        <Nav.Link
                          as={Link}
                          style={{
                            fontSize: "11px",
                            fontFamily: "tahoma",
                            fontWeight: "bold",
                          }}
                          className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                          to="/ManufacturingRequestOrders"
                        >
                          <RiCoupon5Line size="20" />
                          &nbsp;Manufacture Request(PMR)
                        </Nav.Link>
                        {/* <Nav.Link
                      as={Link}
                      style={{
                        color: "black",
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Recipes"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Product Manufacturing Procedure and
                      Estimation
                    </Nav.Link> */}
                      </>
                    )}

                    <Nav.Link
                      as={Link}
                      style={{
                        color: "black",
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Recipes"
                    >
                      <RiCoupon5Line size="20" />
                      {this.context.store.businessTypeId == 3 ? (
                        <>&nbsp;Recipes & Estimation</>
                      ) : (
                        <>&nbsp;Procedure and Estimation</>
                      )}
                    </Nav.Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* Servicing */}
        {this.context.storeSettings.isServiceManagementBusiness == true ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.enablePortalSettingsMenu()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "servicing" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "servicing";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      {/* <BsFillCartCheckFill size="20" /> */}
                      <TbPackage size="20"></TbPackage>
                      {this.context.store.businessTypeId == 3 ? (
                        <></>
                      ) : (
                        <>&nbsp;Servicing </>
                      )}
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Link
                      as={Link}
                      style={{
                        color: "black",
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/ProductServiceCustomers"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Customers DB
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        color: "black",
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/ProductServiceBookings"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Service Booking
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        color: "black",
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/ProductServiceTaskInventory"
                    >
                      <RiCoupon5Line size="20" />
                      <>&nbsp;Serivicing Tasks Inventory</>
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      style={{
                        color: "black",
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/ProductServiceRequests"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Servicing Request(PSR)
                    </Nav.Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* CRM */}
        <ListGroup.Item
          className="px-0 py-0 mx-0 my-0"
          hidden={this.enableCustomersMenu()}
        >
          <Accordion
            defaultActiveKey="1"
            className="mx-0 px-0 py-0 my-0 mb-0 border-0"
          >
            <Accordion.Item
              eventKey={
                this.context.selectedMenuItem === "customer" ? "1" : "0"
              }
              className="border-0 mx-0 px-0 py-0 my-0 mb-0"
              onClick={(e) => {
                this.context.selectedMenuItem = "customer";
              }}
            >
              <Accordion.Header className="mx-0 p-lg-0  text-start">
                <Nav.Link
                  as={Link}
                  className="p-lg-0 bg-white border-0 text-dark text-start"
                  style={{
                    fontSize: "11px",
                    fontFamily: "tahoma",
                    fontWeight: "bold",
                  }}
                  to=""
                >
                  <BsPeopleFill size="20" />
                  &nbsp;Customers (CRM)
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <Nav.Link
                  as={Link}
                  style={{
                    fontSize: "11px",
                    fontFamily: "tahoma",
                    fontWeight: "bold",
                  }}
                  className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                  to="/Customers"
                >
                  <BsPeopleFill size="20" />
                  &nbsp;Customers
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  style={{
                    fontSize: "11px",
                    fontFamily: "tahoma",
                    fontWeight: "bold",
                  }}
                  className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                  to="/Coupon"
                >
                  <RiCoupon5Line size="20" />
                  &nbsp;Coupons
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  style={{
                    color: "black",
                    fontSize: "11px",
                    fontFamily: "tahoma",
                    fontWeight: "bold",
                  }}
                  className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                  to="/SalesLeads"
                >
                  <RiCoupon5Line size="20" />
                  &nbsp;Leads Management
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ListGroup.Item>

        {/* HRM */}
        {this.context.storeSettings.showHrmModule == true ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.context.enableEmployees()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={this.context.selectedMenuItem === "hrm" ? "1" : "0"}
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "hrm";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      as={Link}
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      <BsPeopleFill size="20" />
                      &nbsp;Employees (HRM)
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Departments"
                    >
                      <BsPeopleFill size="20" />
                      &nbsp;Departments
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Employees"
                    >
                      <BsPeopleFill size="20" />
                      &nbsp;Employee DB
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/EmployeeTimesheet"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Timesheet
                    </Nav.Link>
                    {/*<Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Performance
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Claims
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Holiday Calender
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Queries
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Action Items
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;SKills Inventory
                    </Nav.Link> */}

                    {/* 
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <BsPeopleFill size="20" />
                      &nbsp;Projects
                    </Nav.Link>
                    
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Jobs & Careers
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Recruitment
                    </Nav.Link>
                    
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Training
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                      to="/Home"
                    >
                      <RiCoupon5Line size="20" />
                      &nbsp;Employee Exit
                    </Nav.Link> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* Accounts */}
        {this.context.storeSettings.showFinanceModule == true ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.context.enableAccounting()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "account" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "account";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      as={Link}
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      <BsPeopleFill size="20" />
                      &nbsp;Accounts
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountExpensesCategorys"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Account Category
                      </Nav.Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountExpenses"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Expenses/Debits
                      </Nav.Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountRevenues"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Revenues/Credits
                      </Nav.Link>
                    </ListGroup.Item>

                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountReceiveables"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Receivable (AR)
                      </Nav.Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountPayables"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Payable (AP)
                      </Nav.Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountPayroll"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Employee Payroll
                      </Nav.Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/AccountLedger"
                      >
                        <BsPeopleFill size="20" />
                        &nbsp;Ledger
                      </Nav.Link>
                    </ListGroup.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}

        {/* Reports */}
        <ListGroup.Item
          className="px-0 py-0 mx-0 my-0"
          hidden={this.enablePortalSettingsMenu()}
        >
          <Nav.Link
            as={Link}
            style={{
              color: "black",
              fontSize: "11px",
              fontFamily: "tahoma",
              fontWeight: "bold",
            }}
            className="w-100 py-2 my-2 mb-0 bg-white border-0 text-dark text-start"
            to="/Reports"
          >
            <TbReportAnalytics size="20" />
            &nbsp;Reports
          </Nav.Link>
        </ListGroup.Item>
        {/* Storage */}
        <ListGroup.Item
          className="px-0 py-0 mx-0 my-0"
          hidden={this.enableProductsMenu()}
        >
          <Nav.Link
            as={Link}
            style={{
              fontSize: "11px",
              fontFamily: "tahoma",
              fontWeight: "bold",
            }}
            className="w-100 py-2 my-2 mb-0 bg-white border-0 text-dark text-start"
            to="/Images"
          >
            <TiCloudStorage size="20" />
            &nbsp;Storage
          </Nav.Link>
        </ListGroup.Item>
        {/* eCommerce Settings */}
        <ListGroup.Item
          className="px-0 py-0 mx-0 my-0"
          hidden={this.enablePortalSettingsMenu()}
        >
          <Accordion
            defaultActiveKey="1"
            className="mx-0 px-0 py-0 my-0 mb-0 border-0"
          >
            <Accordion.Item
              eventKey={
                this.context.selectedMenuItem === "portalsettings" ? "1" : "0"
              }
              className="border-0 mx-0 px-0 py-0 my-0 mb-0"
              onClick={(e) => {
                this.context.selectedMenuItem = "portalsettings";
              }}
            >
              <Accordion.Header className="mx-0 p-lg-0  text-start">
                <Nav.Link
                  as={Link}
                  className="p-lg-0 bg-white border-0 text-dark text-start"
                  style={{
                    fontSize: "11px",
                    fontFamily: "tahoma",
                    fontWeight: "bold",
                  }}
                  to=""
                >
                  <BsFillLaptopFill size="20" />
                  &nbsp;Customer Portal
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/HomeSettingsCarousel"
                  >
                    <BsFillFileImageFill size="20" />
                    &nbsp;Home Carosel Images
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/HomeSettingsMainMenu"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Main-Menu Configuration
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/HomeSettingsTop5Ads"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Top Ads
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/ClientCertificate"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Certificates
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/ClientLogo"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Client Logos
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/ClientTestimonial"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Testimonials
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/ClientDisplayCounter"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Display Counters
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/HomeSettingsVlog"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Vlogs
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/HomeSettingsBlog"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Blogs
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/Themes"
                  >
                    <BsMenuDown size="20" />
                    &nbsp;Themes
                  </Nav.Link>
                </ListGroup.Item>

                {/* <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                    to="/Products"
                  >
                    <SiHackthebox size="20" />
                    &nbsp;Portal Themes
                  </Nav.Link>
                </ListGroup.Item> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ListGroup.Item>
        {/* Admin */}
        <ListGroup.Item
          className="px-0 py-0 mx-0 my-0"
          hidden={this.enableAdminMenu()}
        >
          <Accordion
            defaultActiveKey="1"
            className="mx-0 px-0 py-0 my-0 mb-0 border-0"
          >
            <Accordion.Item
              eventKey={this.context.selectedMenuItem === "admin" ? "1" : "0"}
              className="border-0 mx-0 px-0 py-0 my-0 mb-0"
              onClick={(e) => {
                this.context.selectedMenuItem = "admin";
              }}
            >
              <Accordion.Header className="mx-0 p-lg-0  text-start">
                <Nav.Link
                  as={Link}
                  className="p-lg-0 bg-white border-0 text-dark text-start"
                  style={{
                    fontSize: "11px",
                    fontFamily: "tahoma",
                    fontWeight: "bold",
                  }}
                  to=""
                >
                  <BsPersonSquare size="20" />
                  &nbsp;Admin
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                {/* <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/Category"
                  >
                    <SiHackthebox size="20" />
                    &nbsp;Roles
                  </Nav.Link>
                </ListGroup.Item> */}
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/Users"
                  >
                    <BsPersonCheckFill size="20" />
                    &nbsp;Admin Users
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/PosUsers"
                  >
                    <BsPersonCheckFill size="20" />
                    &nbsp;POS Users
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/ShippingConfig"
                  >
                    <BsTruck size="20" />
                    &nbsp;Shipping / Delivery
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/TaxesConfig"
                  >
                    <TbReceiptTax size="20" />
                    &nbsp;Taxes
                  </Nav.Link>
                </ListGroup.Item>
                {this.context.store.businessTypeId == 3 ? (
                  <>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/RestaurantTables"
                      >
                        <TbReceiptTax size="20" />
                        &nbsp;Retaurant Tables
                      </Nav.Link>
                    </ListGroup.Item>
                  </>
                ) : (
                  ""
                )}
                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/MultiCurrencys"
                  >
                    <TbReceiptTax size="20" />
                    &nbsp;Multi Country & Currency Settings
                  </Nav.Link>
                </ListGroup.Item>

                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/StoreSettings"
                  >
                    <TbBuildingStore size="20" />
                    &nbsp;Store Settings
                  </Nav.Link>
                </ListGroup.Item>
                {/* <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                  <Nav.Link
                    as={Link}
                    style={{
                      fontSize: "11px",
                      fontFamily: "tahoma",
                      fontWeight: "bold",
                    }}
                    className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                    to="/Home"
                  >
                    <SiHackthebox size="20" />
                    &nbsp;Payment Gateway
                  </Nav.Link>
                </ListGroup.Item> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ListGroup.Item>

        {this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ? (
          <>
            <ListGroup.Item
              className="px-0 py-0 mx-0 my-0"
              hidden={this.enableProductAdminMenu()}
            >
              <Accordion
                defaultActiveKey="1"
                className="mx-0 px-0 py-0 my-0 mb-0 border-0"
              >
                <Accordion.Item
                  eventKey={
                    this.context.selectedMenuItem === "admin" ? "1" : "0"
                  }
                  className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                  onClick={(e) => {
                    this.context.selectedMenuItem = "admin";
                  }}
                >
                  <Accordion.Header className="mx-0 p-lg-0  text-start">
                    <Nav.Link
                      as={Link}
                      className="p-lg-0 bg-white border-0 text-dark text-start"
                      style={{
                        fontSize: "11px",
                        fontFamily: "tahoma",
                        fontWeight: "bold",
                      }}
                      to=""
                    >
                      <BsPersonSquare size="20" />
                      &nbsp;ProductSupport
                    </Nav.Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          fontSize: "11px",
                          fontFamily: "tahoma",
                          fontWeight: "bold",
                        }}
                        className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                        to="/ProductThemes"
                      >
                        <BsPersonCheckFill size="20" />
                        &nbsp;Product Themes
                      </Nav.Link>
                    </ListGroup.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          </>
        ) : (
          ""
        )}
        {window.location.hostname == "lifestyleaqua-admin.elasticcart.com" ? (
          <>
            <ListGroup.Item className="px-0 py-2 mx-0 my-0">
              <Nav.Link
                as={Link}
                style={{
                  fontSize: "11px",
                  fontFamily: "tahoma",
                  fontWeight: "bold",
                }}
                className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                to="/LifeStyleAquaAuthenticator"
              >
                <BsFillFileImageFill size="20" />
                &nbsp;Authenticator
              </Nav.Link>
            </ListGroup.Item>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

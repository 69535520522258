import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill, BsFillPrinterFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import ReactDOM from "react-dom";
import Select from "react-select";
import Fuse from "fuse.js";

import {
  OverlayTrigger,
  Tooltip,
  Stack,
  FormControl,
  InputGroup,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";
import { TbTower } from "react-icons/tb";

export class RawMaterialPurchaseRequests extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      rawmaterialpurchaserequests: [],
      rawmaterialcategory: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      rawmaterialsuppliers: [],
      prods: [],
      loaded: false,
    };
  }
  GetRawMaterialCategories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialCategories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawmaterialcategory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetRawMaterialsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ prods: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  GetRawMaterialPurchaseRequest = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialPurchaseRequest/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawmaterialpurchaserequests: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ rawmaterialpurchaserequests: [] }, () => {
      this.GetRawMaterialPurchaseRequest();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    if (this.state.loaded == false) {
      this.GetRawMaterialPurchaseRequest();
      this.GetRawMaterialsInventories();
      this.GetRawMaterialCategories();
      this.state.loaded = true;
    }
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;RawMaterial Purchase Requests (PR) &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <RawMaterialPurchaseRequestAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          rawmaterialsuppliers={this.state.rawmaterialsuppliers}
                          prods={this.state.prods}
                          rawmaterialcategory={this.state.rawmaterialcategory}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      <th></th>
                      <th width="15%">Created</th>
                      <th width="35%">Name</th>
                      <th width="15%">Status</th>
                      <th width="15%">Description</th>
                      {/* <th width="15%">Tot.Items</th>
                      <th width="15%">Cost Required</th> */}
                      <th width="20%" className="text-end"></th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.rawmaterialpurchaserequests
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((purchaseRequest, i) =>
                        this.state.reload === true ? (
                          <EditRawMaterialPurchaseRequestInline
                            index={i}
                            purchaseRequest={purchaseRequest}
                            OnDeleted={this.OnDeleted}
                            rawmaterialsuppliers={
                              this.state.rawmaterialsuppliers
                            }
                            prods={this.state.prods}
                            rawmaterialcategory={this.state.rawmaterialcategory}
                          ></EditRawMaterialPurchaseRequestInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditRawMaterialPurchaseRequestInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      purchaseRequest: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.purchaseRequest.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteRawMaterialsPurchaseRequest/" +
        this.state.purchaseRequest.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This PurchaseRequest may be associated with few Products. First disassociate this purchaseRequest from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({ purchaseRequest: this.props.purchaseRequest });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };
  getSupplierName = (id) => {
    var sup = this.props.rawmaterialsuppliers.filter((s) => s.id == id);
    if (sup.length > 0) return sup[0].name;
    return "";
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <RawMaterialPurchaseRequestAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            purchaseRequest={this.state.purchaseRequest}
            rawmaterialsuppliers={this.props.rawmaterialsuppliers}
            Type="Edit"
            prods={this.props.prods}
            rawmaterialcategory={this.props.rawmaterialcategory}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
          onDoubleClick={(e) => {
            this.OnEditButtonClick(e);
          }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td>
            {
              this.context.GetInDateFormat(
                new Date(this.state.purchaseRequest.created)
              )
              // new Date(this.state.purchaseRequest.created).getDate() +
              //   "-" +
              //   (new Date(this.state.purchaseRequest.created).getMonth() + 1) +
              //   "-" +
              //   new Date(this.state.purchaseRequest.created).getFullYear()
            }
          </td>
          <td className="text-center ">{this.state.purchaseRequest.name}</td>
          <td className="text-center ">
            {this.state.purchaseRequest.purchased === true
              ? "Purchased"
              : "Request"}
          </td>
          <td className="text-center ">
            {this.state.purchaseRequest.description}
          </td>
          {/* <td></td>
          <td></td> */}
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete associated details as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete associated details as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class RawMaterialPurchaseRequestAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      purchaseRequest: { id: undefined, purchased: false },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      rawMaterialsPurchaseRequestItems: [],
      showOrderItemModal: false,
      editPurchaseItemRow: 0,
      categoryFilterName: "",
      reload: true,
      rawmaterialsinventoryFiltered: [],
    };
  }

  GetPurchaseRequestHTML = () => {
    var str = "<html>";
    str += '<div style="width: 100%;text-align: center">';
    str +=
      "<h4><u>" +
      "Purchase Request" +
      "</u>" +
      " [" +
      this.context.GetInDateFormat(
        new Date(this.state.purchaseRequest.created)
      ) +
      // new Date(this.state.purchaseRequest.created).getDate() +
      // "-" +
      // (new Date(this.state.purchaseRequest.created).getMonth() + 1) +
      // "-" +
      // new Date(this.state.purchaseRequest.created).getFullYear() +
      " ]</h4>";
    str += "<h4>" + this.context.store.name + "</h4>";
    str += "<h5>";
    str += "Name: " + this.state.purchaseRequest.name + "<br/>";
    str +=
      "Description: " + this.state.purchaseRequest.description + "</h5><br/>";
    str += "</div>";

    str += '<table style="width: 100%;border:dashed;text-align:center">';
    str +=
      "<thead ><th></th><th>ItemName</th><th>Weight</th><th>Unit</th><th>Qty</th></thead>";
    this.state.rawMaterialsPurchaseRequestItems.map((item, i) => {
      str +=
        "<tr><td>" +
        (i + 1) +
        "</td>" +
        "<td>" +
        item.itemName +
        "</td>" +
        "<td>" +
        item.weight +
        "</td>" +
        "<td>" +
        item.unit +
        "</td>" +
        "<td>" +
        item.qty +
        "</td></tr>";
    });
    str += "</table></html>";

    return str;
  };
  PrintMe = (invoiceHtml) => {
    var printWindow = window.open("", "_blank");
    printWindow.document.write(this.GetPurchaseRequestHTML());
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };
  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };
  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  OnCategoryChanged = (e) => {
    this.setState({ reload: false });
    this.setState({ categoryFilterName: e.target.value }, () => {
      this.setState({ reload: true });
    });
  };
  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ rawmaterialsinventoryFiltered: this.props.prods }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.rawmaterialsinventory, {
      keys: ["name"],
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState(
        { rawmaterialsinventoryFiltered: finalResult.slice(0, 25) },
        () => {
          this.setState({ reload: true });
        }
      );
    } else {
      this.setState({ rawmaterialsinventoryFiltered: [] });
    }
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetPurchaseRequestById = (Id) => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsPurchaseRequestById/" +
        Id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          // if (data.rawMaterialsPurchaseRequestItems != undefined) {
          //   data.rawMaterialsPurchaseRequestItems =
          //     data.rawMaterialsPurchaseRequestItems.sort((a, b) =>
          //       a.seqNo > b.seqNo ? 1 : -1
          //     );
          // }
          this.setState({ purchaseRequest: data }, () => {
            this.setState({
              rawMaterialsPurchaseRequestItems:
                data.rawMaterialsPurchaseRequestItems,
            });
            // this.setState({ reload: false }, () => {
            //   this.setState({ reload: true });
            // });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  AddOrUpdatePurchaseRequestInDB = () => {
    this.ShowLoadSpinner();

    var tmpPurchaseRequest = this.state.purchaseRequest;
    tmpPurchaseRequest.storeId = this.context.storeId;

    tmpPurchaseRequest.rawMaterialsPurchaseRequestItems =
      this.state.rawMaterialsPurchaseRequestItems;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpPurchaseRequest),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateRawMaterialsPurchaseReuest/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This PurchaseRequest is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdatePurchaseRequestInDB();
  };

  handleSelectionChange = (e, index) => {
    //const { name, value } = e.target;
    var name = "fileName";
    const list = [...this.state.rawMaterialsPurchaseRequestItems];
    list[index][name] = e.value;
    this.setState({ rawMaterialsPurchaseRequestItems: list });
  };

  UpdatePurchaseItem = (item, index) => {
    //this.state.rawMaterialsPurchaseRequestItems[index].sku = item.sku;
    this.state.rawMaterialsPurchaseRequestItems[index].itemName = item.itemName;
    // this.state.rawMaterialsPurchaseRequestItems[index].rate = item.rate;
    this.state.rawMaterialsPurchaseRequestItems[index].qty = item.qty;
    this.state.rawMaterialsPurchaseRequestItems[index].weight = item.weight;
    this.state.rawMaterialsPurchaseRequestItems[index].unit = item.unit;
    // this.state.rawMaterialsPurchaseRequestItems[index].tax = item.tax;
    this.setState(
      {
        rawMaterialsPurchaseRequestItems:
          this.state.rawMaterialsPurchaseRequestItems,
      },
      () => {
        this.setState({ editModalShow: false }, () => {});
      }
    );
  };

  AddPurchaseItem = (item) => {
    this.setState(
      {
        rawMaterialsPurchaseRequestItems: [
          ...this.state.rawMaterialsPurchaseRequestItems,
          {
            //sku: item.sku,
            itemName: item.itemName,
            // rate: item.rate,
            qty: item.qty,
            weight: item.weight,
            unit: item.unit,
            // discount: item.discount,
            // tax: item.tax,
            // seqNo: this.state.rawMaterialsPurchaseRequestItems.length + 1,
          },
        ],
      },
      () => {
        this.onHideOrderItemModal();
      }
    );
  };
  AddPurchaseItem1 = (item) => {
    this.setState(
      {
        rawMaterialsPurchaseRequestItems: [
          ...this.state.rawMaterialsPurchaseRequestItems,
          {
            itemName: item.name,
            qty: 1,
            weight: item.weight,
            unit: item.unit,
          },
        ],
      },
      () => {
        this.onHideOrderItemModal();
      }
    );
  };

  handleRemoveClick = (indexno) => {
    const list = [...this.state.rawMaterialsPurchaseRequestItems];
    list.splice(indexno, 1);
    this.setState({ rawMaterialsPurchaseRequestItems: list }, () => {});
  };
  onHideOrderItemModal = () => {
    this.setState({ showOrderItemModal: false });
    this.setState({ editModalShow: false }, () => {});
  };
  handleAddItems = (e) => {
    this.setState({ showOrderItemModal: true });
  };

  OnEditButtonClick = (e, i) => {
    this.setState({ editPurchaseItemRow: i }, () => {
      this.setState({ editModalShow: true }, () => {});
    });
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      // this.props.OnUpdated();
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "name":
        this.state.purchaseRequest.name = value;
        break;
      case "purchased":
        this.state.purchaseRequest.purchased = value;
        break;
      case "description":
        this.state.purchaseRequest.description = value;
        break;
    }
    this.setState({ purchaseRequest: this.state.purchaseRequest });
  };
  handleRawMaterialsInputChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "qty":
        this.state.rawMaterialsPurchaseRequestItems[i].qty = value;
        break;
      case "weight":
        this.state.rawMaterialsPurchaseRequestItems[i].weight = value;
        break;
      case "unit":
        this.state.rawMaterialsPurchaseRequestItems[i].unit = value;
        break;
    }
    this.setState({
      rawMaterialsPurchaseRequestItems:
        this.state.rawMaterialsPurchaseRequestItems,
    });
  };

  GetPricePerKgOfId = (row) => {
    var result = this.props.prods.filter((f) => f.name == row.itemName);
    if (result.length > 0) {
      if (result[0].unit == "gram" || result[0].unit == "ml") {
        var oneGram = result[0].price / result[0].weight;
        var oneKg = oneGram * 1000;
        return oneKg;
      } else if (
        result[0].unit == "kg" ||
        result[0].unit == "liter" ||
        result[0].unit == "piece"
      ) {
        var oneKg = result[0].price / result[0].weight;
        return oneKg;
      } else return 0;
    } else {
      return 0;
    }
  };
  GetPriceOfIngredient = (row) => {
    var result = this.props.prods.filter((f) => f.name == row.itemName);
    if (result.length > 0) {
      var perKg = this.GetPricePerKgOfId(row);
      if (row.unit == "gram" || row.unit == "ml") {
        var r = (perKg / 1000) * row.weight;
        return r;
      } else if (
        row.unit == "kg" ||
        row.unit == "liter" ||
        row.unit == "piece"
      ) {
        var r = perKg * row.weight;
        return r;
      } else return 0;
    } else return 0;
  };
  GetTotalEstimation = (row) => {
    return this.state.rawMaterialsPurchaseRequestItems.reduce(
      (a, v) => (a = a + this.GetPriceOfIngredient(v) * Number(v.qty)),
      0
    );
  };

  componentDidMount() {
    this.setState({
      rawmaterialsinventoryFiltered: this.props.prods,
    });

    if (this.props.Type == "Edit") {
      this.GetPurchaseRequestById(this.props.purchaseRequest.id);
    }
    if (document.getElementById("name") != null)
      document.getElementById("name").focus();

    document.addEventListener("keydown", (event) => {
      if (event.key == "F8") {
        this.handleAddItems(event);
      }
    });
  }

  componentDidUpdate = () => {};

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xxl"
          style={{
            fontFamily: "sans-serif",
            fontSize: "12px",
          }}
          backdrop="static"
          keyboard={false}
          dialogClassName="purchaserequest-modal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Purchase Request"
                  : "Add Purchase Request"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <div
                className="fixedTableHightOrder"
                style={{
                  height: window.innerHeight - 200 + "px",
                }}
              >
                <Row className="m-0 p-0 bg-white " style={{ fontSize: "9pt" }}>
                  <Col md={4} className="bg-seondary border border-dark">
                    <Row className="text-center text-secondary">
                      <h4>
                        <b>RawMaterial Inventory</b>
                      </h4>
                      <div className="p-3">
                        <Row>
                          <Col>
                            <Stack direction="horizontal" gap={3} className="">
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-search"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </b>
                              <FormControl
                                type="search"
                                placeholder="Search  "
                                className="text-primary fw-bold bg-white "
                                aria-label="Search"
                                onChange={(e) =>
                                  this.onSearchChange(e.target.value)
                                }
                              />
                            </Stack>
                          </Col>
                          <Col>
                            <InputGroup className="">
                              <InputGroup.Text id="basic-addon1">
                                Category
                              </InputGroup.Text>
                              <Form.Select
                                value={this.state.categoryFilterName}
                                onChange={(e) => {
                                  this.OnCategoryChanged(e);
                                }}
                              >
                                <option value=""></option>
                                {this.props.rawmaterialcategory.map((m) => (
                                  <>
                                    <option value={m.id}>{m.name}</option>
                                  </>
                                ))}
                              </Form.Select>
                            </InputGroup>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                    <Row className="text-center py-3">
                      <Col className="text-secondary ">
                        <div className="">
                          <Table
                            border-1
                            className="bg-white  w-100 "
                            width="50%"
                          >
                            <thead className="border-5 ">
                              <tr className="text-center text-dark  border ">
                                <th className="text-start " width="50%">
                                  Product Name
                                </th>
                                <th className="text-start " width="10%">
                                  weight
                                </th>
                                <th className="text-start " width="10%">
                                  Unit
                                </th>
                                <th className="text-start " width="10%">
                                  Price
                                </th>
                                <th className="text-end " width="20%">
                                  Add Product
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-primary border-5  ">
                              {this.state.rawmaterialsinventoryFiltered &&
                                this.state.rawmaterialsinventoryFiltered
                                  .filter((f) => {
                                    if (
                                      this.state.categoryFilterName != "" &&
                                      this.state.categoryFilterName ==
                                        f.categoryId
                                    ) {
                                      return true;
                                    } else if (
                                      this.state.categoryFilterName == ""
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  })
                                  .sort((a, b) =>
                                    a.name.toUpperCase() > b.name.toUpperCase()
                                      ? 1
                                      : -1
                                  )
                                  .map((prod, i) => (
                                    <>
                                      <tr
                                        className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                        style={{ fontSize: "12px" }}
                                      >
                                        <td width="50%">{prod.name}</td>
                                        <td width="10%">{prod.weight}</td>
                                        <td width="10%">{prod.unit}</td>
                                        <td width="10%">
                                          {Number(prod.price).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 1,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </td>
                                        <td width="20%" className="text-end ">
                                          {this.state
                                            .rawMaterialsPurchaseRequestItems &&
                                          this.state.rawMaterialsPurchaseRequestItems.filter(
                                            (p) => p.itemName == prod.name
                                          ).length > 0 ? (
                                            <Button
                                              variant="secondary"
                                              disabled
                                            >
                                              ADDED
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={(e) => {
                                                this.AddPurchaseItem1(prod);
                                              }}
                                            >
                                              {" "}
                                              Add &nbsp;-->
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={8} className="bg-seondary border border-dark">
                    <Row>
                      <Col md={7} className="text-end">
                        <h4>
                          <b>Purchase Request Items</b>
                        </h4>
                      </Col>
                      <Col md={5}>
                        <Button onClick={this.PrintMe}>
                          <BsFillPrinterFill size={20}></BsFillPrinterFill>
                          &nbsp;&nbsp;Print
                        </Button>
                      </Col>
                    </Row>
                    <div>
                      <Row
                        className="m-0 p-0 bg-light border-0"
                        style={{ fontSize: "12pt" }}
                      >
                        <Col md={4}>
                          <Form.Group as={Row} className="mb-3 " controlId="">
                            <Form.Label column sm="3">
                              Name
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                autoFocus
                                id="name"
                                name="name"
                                maxlength="99"
                                value={this.state.purchaseRequest.name}
                                required
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group as={Row} className="mb-3 " controlId="">
                            <Form.Label column sm="3">
                              Status
                            </Form.Label>
                            <Col sm="9">
                              <Form.Select
                                type="text"
                                name="purchased"
                                value={this.state.purchaseRequest.purchased}
                                required
                                className="text-start"
                                placeholder=""
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value={true}>Purchased</option>
                                <option value={false}>New Request</option>
                              </Form.Select>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={5}>
                          <Form.Group as={Row} className=" " controlId="">
                            <Form.Label column sm="3">
                              Desc
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                autoFocus
                                id="description"
                                name="description"
                                maxlength="99"
                                value={this.state.purchaseRequest.description}
                                // required
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="m-0 p-0 bg-light border">
                        <Col>
                          <Table bordered size="sm" className=" text-start">
                            <thead className="bg-secondary text-white">
                              <tr size="lg" className="text-center">
                                <td width="5%"></td>
                                <td width="40%">Item Name</td>
                                <td width="10%">Weight</td>
                                <td width="10%">Unit</td>
                                <td width="10%">Price</td>
                                <td width="10%">Qty</td>
                                <td width="10%">
                                  Estimation
                                  <br />
                                  <h5 className="text-warning">
                                    [Tot:{" "}
                                    {Number(
                                      this.GetTotalEstimation()
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                    ]
                                  </h5>
                                </td>
                                <td width="5%" className="text-center">
                                  Options
                                </td>
                              </tr>
                            </thead>
                            <tbody size="lg">
                              {this.state.rawMaterialsPurchaseRequestItems !=
                              undefined
                                ? this.state.rawMaterialsPurchaseRequestItems
                                    //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                    .map((row, i) => (
                                      <>
                                        <tr
                                          height="30pt"
                                          className="align-middle text-center"
                                        >
                                          <td className="text-center">
                                            {i + 1}
                                          </td>
                                          <td className="text-start">
                                            {row.itemName}
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              name="weight"
                                              value={row.weight}
                                              placeholder=""
                                              min={0}
                                              align="center"
                                              className="text-center  "
                                              autoComplete="off"
                                              onChange={(e) =>
                                                this.handleRawMaterialsInputChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-start ">
                                            {/* {this.state.rawmaterialsinventory.name} */}
                                            <Form.Select
                                              type="text"
                                              name="unit"
                                              value={row.unit}
                                              placeholder=""
                                              min={0}
                                              align="center"
                                              className="text-start "
                                              autoComplete="off"
                                              onChange={(e) =>
                                                this.handleRawMaterialsInputChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            >
                                              <option value={""}></option>
                                              <option value={"piece"}>
                                                piece
                                              </option>
                                              <option value={"gram"}>
                                                gram
                                              </option>
                                              <option value={"kg"}>kg</option>
                                              <option value={"ml"}>ml</option>
                                              <option value={"liter"}>
                                                liter
                                              </option>
                                              <option value={"meter"}>
                                                meter
                                              </option>
                                            </Form.Select>
                                          </td>
                                          <td className="text-end">
                                            {Number(
                                              this.GetPriceOfIngredient(row)
                                            ).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              name="qty"
                                              value={row.qty}
                                              required
                                              placeholder=""
                                              min={0}
                                              align="center"
                                              className="text-center  "
                                              autoComplete="off"
                                              onChange={(e) =>
                                                this.handleRawMaterialsInputChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </td>

                                          <td className="text-end">
                                            {Number(
                                              this.GetPriceOfIngredient(row) *
                                                row.qty
                                            ).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </td>
                                          <td className="text-center">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>Delete!</Tooltip>
                                              }
                                            >
                                              <Button
                                                variant="outline-danger"
                                                onClick={(e) => {
                                                  this.handleRemoveClick(i);
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-trash"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                  />
                                                </svg>
                                              </Button>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                : ""}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

import React, { Component, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { unmountComponentAtNode, render } from "react-dom";
import { SiHackthebox } from "react-icons/si";
import { GiTable } from "react-icons/gi";
import { GoListOrdered } from "react-icons/go";
import { BsFillBagFill, BsFullscreen } from "react-icons/bs";
import { RiFeedbackLine } from "react-icons/ri";
import RefreshOrderSummary from "./RefreshOrderSummary";
import { FiRefreshCcw } from "react-icons/fi";
import Swal from "sweetalert2";
import { TbCashRegister } from "react-icons/tb";
import { GiCash } from "react-icons/gi";

import {
  BsCartX,
  BsArrowRight,
  BsTruck,
  BsCashCoin,
  BsDoorClosed,
  BsClockHistory,
  BsArrowReturnLeft,
  BsPeopleFill,
} from "react-icons/bs";

import {
  Navbar,
  Tooltip,
  Nav,
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";

import CartContext from "./CartContext";

import "../CSS/textanimation.css";

export default class Navigation extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      storeId: "",
      showOverlay: false,
    };
  }

  handleOnMouseEnter = () => {
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    this.setState({ showOverlay: false });
  };

  GetMyStoreDetails = () => {
    console.log(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/");
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert(
            "This Application is not active yet. Please contact your Vendor!"
          );
        } else {
          let expirydate = new Date(data.storeEnd);
          const diffTime = Math.abs(new Date() - expirydate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (new Date(data.storeEnd) >= new Date() == false) {
            alert(
              "Information: Store License has expired. Kindly contact our Support Team for Renewal!"
            );
            return;
          } else if (diffDays <= 15) {
            alert(
              "Renewal Remainder! Store License will expire on " +
                this.context.GetInDateFormat(new Date(data.storeEnd)) +
                ". Kindly contact our Support Team for Renewal!"
            );
          }

          this.context.setStoreId(data.id);
          if (this.state.storeId != "") return;
          this.setState({ storeId: data.id }, () => {
            this.LoadAllData("firsttime");
            // this.context.GetStore();
            // this.context.GetStoreSettings();
            // this.context.GetPosUserRoles();
            // this.context.GetMainCarousels(); //1-desktop 2-Mobile
            // this.context.GetOrderStatusTypes();
            // this.context.GetProductGroups();
            // this.context.getMainCategories();
            // this.context.getSubCategories();
            // this.context.getBrands();
            // this.context.GetAllProducts();
            // this.context.GetMainMenus();
            // this.context.GetTop5Ads();
            // this.context.GetAllImageNames();
            // this.context.GetStoreRoles();
            // this.context.GetOrdersSummary();
            // this.context.GetCustomerOrderPaymentStatusTypes();
            // this.context.getTaxDetails();
            // this.context.getThemes();
            // this.context.getProductThemes();
            // this.context.GetRestaturantTables();
            // this.context.GetRestaurantTableOrdereStatus();
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("GetMyStoreDetails fetch err:" + error);
      });
  };

  LoadAllData = (arg) => {
    this.context.GetStore();
    this.context.GetStoreSettings();
    this.context.GetPosUserRoles();
    this.context.GetMainCarousels(); //1-desktop 2-Mobile
    this.context.GetOrderStatusTypes();
    this.context.GetProductGroups();
    this.context.getMainCategories();
    this.context.getSubCategories();
    this.context.getBrands();
    this.context.GetAllProducts();
    this.context.GetMainMenus();
    this.context.GetTop5Ads();
    this.context.GetAllImageNames();
    this.context.GetStoreRoles();
    this.context.GetOrdersSummary();
    this.context.GetCustomerOrderPaymentStatusTypes();
    this.context.getTaxDetails();
    this.context.getThemes();
    this.context.getProductThemes();
    this.context.GetRestaturantTables();
    this.context.GetRestaurantTableOrdereStatus();
    this.context.GetManufacturingStatusTypes();
    this.context.GetRawMaterialsInventories();
    this.context.GetProductServiceStatusTypes();
    this.context.GetProductServiceTasksInventories();
    this.context.GetClientCertificates();
    this.context.GetClientLogos();
    this.context.GetClientTestimonials();
    this.context.GetVlogs();
    this.context.GetBlogs();

    if (arg != "firsttime") {
      Swal.fire({
        title: "refreshing..please wait",
        // confirmButtonText: "yes",
        timer: 5000,
      }).then((result) => {
        this.setState({ searchsku: "" });
      });
    }
  };

  componentDidMount() {
    if (this.state.storeId == "") {
      this.GetMyStoreDetails();
    }
    // if (this.context.store.Id != undefined) {
    //   this.context.GetAllImageNames();
    // }
  }
  componentDidUpdate() {}

  render() {
    if (this.context.storeId === "") {
      // return <Navigate to="/UserLogin" />;
      return <Navigate to="/Home" />;
    }
    return (
      <>
        {this.context.userData.loginStatus == "active" ? (
          <>
            <RefreshOrderSummary></RefreshOrderSummary>
          </>
        ) : (
          <></>
        )}

        <div className="Container-fluid  m-0 p-0 w-100">
          <Navbar className="mainnavbar mainthemecolor position-sticky fixed-top m-0 py-0 ">
            {/* logo and title */}
            <Navbar.Collapse
              className="justify-content-start m-0 p-0 border-0"
              width="30%"
            >
              <Nav>
                <Nav.Link
                  as={Link}
                  to="/home"
                  className="mx-2 p-1 border-0 border-info text-center "
                >
                  <span>
                    <img
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.context.storeSettings.storeLogoFileName +
                        this.context.store.storageSasToken
                      }
                      // className="border-0 rounded-pill bg-white invertimage"
                      className="border-0  bg-white rounded-pill "
                      style={{
                        width: "3cm",
                        height: "1cm",
                        objectFit: "contain",
                      }}
                    />
                  </span>
                  <br />
                  <small className="text-center" style={{ color: "white" }}>
                    ver:&nbsp;
                    {this.context.REACT_APP_VERSION}
                  </small>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

            {/* <MainMenu></MainMenu> */}

            {this.context.userData.loginStatus == "active" ? (
              <>
                <Navbar.Collapse
                  className=" justify-content-center mx-0 border-0 m-0 p-2"
                  width="40%"
                >
                  {this.context.storeSettings.isQuotationSupported == true ? (
                    <>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3 "
                        to="/QuotationRequest"
                        hidden={this.context.enableQuotationToolbarIcon()}
                      >
                        <GiCash size="20" />
                        <Badge
                          bg="primary"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary.quotesCount}
                        </Badge>
                        <br />
                        <small>Quotations</small>
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3 "
                        to="/SalesLeads"
                        hidden={this.context.enableQuotationToolbarIcon()}
                      >
                        <GiCash size="20" />
                        <Badge
                          bg="primary"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary.saleLeadsCount}
                        </Badge>
                        <br />
                        <small>Sale Leads</small>
                      </Nav.Link>
                      {/* <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3 "
                        to="/QuotationRequest"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <GiCash size="20" />
                        <Badge
                          bg="primary"
                          className="notify-badge-right1 text-white"
                        >
                          0
                        </Badge>
                        <br />
                        <small>Payment History</small>
                      </Nav.Link> */}
                      <Button
                        className="border-0"
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "32px",
                        }}
                      >
                        {" "}
                        |{" "}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}

                  {this.context.storeSettings.showOrdersManagement == true ? (
                    <>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3 "
                        to="/Orders"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <GoListOrdered size="20" />
                        <Badge
                          bg="primary"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderReceived
                            : ""}
                        </Badge>
                        <br />
                        <small>1. New Orders</small>
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      {this.context.store.businessTypeId == 3 ? (
                        <>
                          <Nav.Link
                            as={Link}
                            className="navBarLink text-center  px-3"
                            to="/Kds2"
                            hidden={this.context.enableOrderToolbarIcon()}
                          >
                            <BsFullscreen size="20"></BsFullscreen>
                            <Badge
                              bg="success"
                              className="notify-badge-right1 text-white"
                            >
                              {this.context.orderSummary != ""
                                ? this.context.orderSummary.orderPrepare
                                : ""}
                            </Badge>
                            <br />
                            <small>2. Prepare</small>
                          </Nav.Link>
                        </>
                      ) : (
                        <>
                          <Nav.Link
                            as={Link}
                            className="navBarLink text-center  px-3"
                            to="/Kds2"
                            hidden={this.context.enableOrderToolbarIcon()}
                          >
                            <BsFullscreen size="20"></BsFullscreen>
                            <Badge
                              bg="success"
                              className="notify-badge-right1 text-white"
                            >
                              {this.context.orderSummary != ""
                                ? this.context.orderSummary.orderPrepare
                                : ""}
                            </Badge>
                            <br />
                            <small>2. Prepare</small>
                          </Nav.Link>
                        </>
                      )}
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/Package"
                        hidden={this.context.enablePackaingToolbarIcon()}
                        onClick={(e) => {}}
                      >
                        <BsFillBagFill size="20" />
                        <Badge
                          bg="danger"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderPackage
                            : ""}
                        </Badge>
                        <br />
                        <small>3. Packaging</small>
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/Dispatch"
                        hidden={this.context.enableShippingToolbarIcon()}
                      >
                        <BsTruck size="20" />
                        <Badge
                          bg="danger"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderShip
                            : ""}
                        </Badge>
                        <br />
                        <small>4. Shipping </small>
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/Delivery"
                        hidden={this.context.enableDeliveryToolbarIcon()}
                      >
                        {/* <BsTruck size="20" /> */}
                        <img
                          width="26px"
                          height="26px"
                          className="invertimage"
                          style={{ objectFit: "contain", color: "white" }}
                          src="images/delivery.png"
                        />
                        <Badge
                          bg="danger"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderDelivery
                            : ""}
                        </Badge>
                        <br />
                        <small>5. Delivery</small>
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/Payment"
                        hidden={this.context.enablePaymentToolbarIcon()}
                      >
                        <BsCashCoin size="20" />
                        <Badge
                          bg="danger"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderPayment
                            : ""}
                        </Badge>
                        <br />
                        <small>6. Payment</small>
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/CloseOrder"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <BsDoorClosed size="20" />
                        <Badge
                          bg="warning"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderClose
                            : ""}
                        </Badge>
                        <br />
                        <small>7. CloseOrders</small>
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/OrderHistory"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <BsClockHistory size="20" />
                        <Badge
                          bg="secondary"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderHistory
                            : ""}
                        </Badge>
                        <br />
                        <small>8. History</small>
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  p-0 m-0"
                        to=""
                      >
                        <BsArrowRight
                          className="text-primary fw-bold"
                          size="20"
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        className="navBarLink text-center  px-3"
                        to="/Returns"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <BsArrowReturnLeft size="20" />
                        <Badge
                          bg="danger"
                          className="notify-badge-right1 text-white"
                        >
                          {this.context.orderSummary != ""
                            ? this.context.orderSummary.orderReturned
                            : ""}
                        </Badge>
                        <br />
                        <small>Returns</small>
                      </Nav.Link>
                    </>
                  ) : (
                    <></>
                  )}
                </Navbar.Collapse>
              </>
            ) : (
              ""
            )}

            <Navbar.Collapse
              className=" mx-2 p-0 justify-content-end text-end border-0"
              width="30%"
            >
              <Nav>
                <Nav.Link
                  as={Link}
                  className="py-1 navBarLink posbutton"
                  to={"https://" + this.context.store.storeDomainName}
                  target="_blank"
                  style={{ fontSize: "10px" }}
                >
                  eCom
                </Nav.Link>
              </Nav>
              &nbsp;
              <Nav>
                <Nav.Link
                  as={Link}
                  className="py-1 navBarLink posbutton"
                  to={"https://" + this.context.store.storePosDomainName}
                  target="_blank"
                  style={{ fontSize: "10px" }}
                >
                  POS
                </Nav.Link>
              </Nav>
              <Nav className="navBarLink m-0 p-0 justify-content-end text-end">
                <Nav.Link
                  as={Link}
                  className="py-1 navBarLink "
                  to=""
                  onClick={(e) => this.LoadAllData("")}
                >
                  <OverlayTrigger overlay={<Tooltip>Refresh</Tooltip>}>
                    <FiRefreshCcw
                      size={"20px"}
                      style={{ fontFamily: "Tahoma", color: "yellow" }}
                    ></FiRefreshCcw>
                  </OverlayTrigger>
                </Nav.Link>
                {/* Signup/Login */}

                <Nav.Link
                  as={Link}
                  className="navBarLink border-0 m-0 p-0 justify-content-end text-end"
                  to="/UserLogin"
                  hidden={this.context.userData.hideSignupLink}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                  &nbsp; Login
                </Nav.Link>

                {/* User */}
                <OverlayTrigger
                  show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                  key="bottom"
                  placement="bottom"
                  rootClose="false"
                  overlay={
                    <Popover
                      id={`popover-positioned-bottom`}
                      onMouseEnter={this.handleOnMouseEnter}
                      onMouseLeave={this.handleOnMouseLeave}
                      className="bg-dark"
                    >
                      <Popover.Header as="h3"></Popover.Header>
                      <Popover.Body>
                        <UserMenu></UserMenu>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    // style={{ height: "30px", width: "160px" }}
                    // variant="dark"
                    className="mainthemecolor border-0 justify-content-end text-end"
                    hidden={!this.context.userData.hideSignupLink}
                    onMouseEnter={this.handleOnMouseEnter}
                    onMouseLeave={this.handleOnMouseLeave}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path
                          fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                        />
                      </svg>
                    </span>
                  </Button>
                </OverlayTrigger>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
}

class UserMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ListGroup variant="flush" className="fw-bold bg-dark">
          <span className="text-white my-2">
            Hi&nbsp;
            {this.context.userData.emailId} !
          </span>
          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/UserChangePassword"
          >
            Change Password
          </ListGroup.Item>
          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/home"
            onClick={(e) => {
              this.context.clearUserData();
            }}
          >
            Sign Out
          </ListGroup.Item>
        </ListGroup>
      </>
    );
  }
}

import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill, BsFillPrinterFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import ReactDOM from "react-dom";
import Select from "react-select";
import Fuse from "fuse.js";
import DatePicker from "react-date-picker";
import makeAnimated from "react-select/animated";

import {
  OverlayTrigger,
  Tooltip,
  Stack,
  FormControl,
  InputGroup,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";
import { TbTower } from "react-icons/tb";

export class PurchaseRequests extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      purchaserequests: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      loaded: false,
      purchaseStatus: false,
      suppliers: [],
    };
  }

  GetSuppliers = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetSuppliers/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ suppliers: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  OnPurchaseStatusChanged = (e) => {
    this.setState(
      { purchaseStatus: e.target.value == "true" ? true : false },
      () => {
        this.setState({ reload: false }, () => {
          this.setState({ reload: true });
        });
      }
    );
  };

  GetPurchaseRequest = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPurchaseRequest/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ purchaserequests: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ purchaserequests: [] }, () => {
      this.GetPurchaseRequest();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.loaded == false) {
      this.GetPurchaseRequest();
      this.GetSuppliers();
      this.state.loaded = true;
    }
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp; Purchase Requests (PR) &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        size="sm"
                        className="mainthemecolor"
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        size="sm"
                        className="mainthemecolor"
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="16"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <PurchaseRequestAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          prods={this.context.prods}
                          category={this.context.mainCategories}
                          suppliers={this.state.suppliers}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <InputGroup className="">
                <InputGroup.Text id="basic-addon1">Status</InputGroup.Text>
                <Form.Select
                  value={this.state.purchaseStatus}
                  onChange={(e) => {
                    this.OnPurchaseStatusChanged(e);
                  }}
                >
                  <option value={false}>New Request</option>
                  <option value={true}>Purchased</option>
                </Form.Select>
              </InputGroup>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      <th></th>
                      <th width="20%">Created</th>
                      <th width="20%">Request Number/Title</th>
                      <th width="20%">Status</th>
                      {window.innerWidth > 1200 ? (
                        <>
                          <th width="20%">Description</th>{" "}
                        </>
                      ) : (
                        <></>
                      )}

                      <th width="20%" className="text-end">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.reload == true ? (
                      <>
                        {this.state.purchaserequests
                          .filter(
                            (f) => f.purchased == this.state.purchaseStatus
                          )
                          .sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((purchaseRequest, i) =>
                            this.state.reload === true ? (
                              <EditPurchaseRequestInline
                                index={i}
                                purchaseRequest={purchaseRequest}
                                OnDeleted={this.OnDeleted}
                                prods={this.context.prods}
                                category={this.context.mainCategories}
                                refresh={this.OnRefreshButtonClick}
                                suppliers={this.state.suppliers}
                              ></EditPurchaseRequestInline>
                            ) : null
                          )}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditPurchaseRequestInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      purchaseRequest: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.purchaseRequest.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeletePurchaseRequest/" +
        this.state.purchaseRequest.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This PurchaseRequest may be associated with few Products. First disassociate this purchaseRequest from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({ purchaseRequest: this.props.purchaseRequest });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
    this.props.refresh();
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <PurchaseRequestAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            purchaseRequest={this.state.purchaseRequest}
            Type="Edit"
            prods={this.props.prods}
            category={this.props.category}
            suppliers={this.props.suppliers}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td>
            {
              this.context.GetInDateFormat(
                new Date(this.state.purchaseRequest.created)
              )
              // new Date(this.state.purchaseRequest.created).getDate() +
              //   "-" +
              //   (new Date(this.state.purchaseRequest.created).getMonth() + 1) +
              //   "-" +
              //   new Date(this.state.purchaseRequest.created).getFullYear()
            }
          </td>
          <td className="text-center ">{this.state.purchaseRequest.name}</td>
          <td className="text-center ">
            {this.state.purchaseRequest.purchased === true
              ? "Purchased"
              : "New Request"}
          </td>
          {window.innerWidth > 1200 ? (
            <>
              <td className="text-center ">
                {this.state.purchaseRequest.description}
              </td>
            </>
          ) : (
            <></>
          )}

          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="16" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete associated details as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete associated details as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class PurchaseRequestAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      purchaseRequest: { id: undefined, purchased: false },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      purchaseRequestItems: [],
      showOrderItemModal: false,
      editPurchaseItemRow: 0,
      categoryFilterName: "",
      brandFilterName: "",
      reload: true,
      inventoryFiltered: [],
      selectedSuppliers: [],
      suppliersForSelect: [],
      refreshSelectedSuppliers: true,
    };
  }

  GetPurchaseRequestHTML = () => {
    var str = "<html>";
    str += '<div style="width: 100%;text-align: center">';
    str +=
      "<h4><u>" +
      "Purchase Request" +
      "</u>" +
      " [" +
      this.context.GetInDateFormat(
        new Date(this.state.purchaseRequest.created)
      ) +
      // new Date(this.state.purchaseRequest.created).getDate() +
      // "-" +
      // (new Date(this.state.purchaseRequest.created).getMonth() + 1) +
      // "-" +
      // new Date(this.state.purchaseRequest.created).getFullYear() +
      " ]</h4>";
    str += "<h4>" + this.context.store.name + "</h4>";
    str += "<h5>";
    str += "Name: " + this.state.purchaseRequest.name + "<br/>";
    str +=
      "Description: " + this.state.purchaseRequest.description + "</h5><br/>";
    str += "</div>";

    str += '<table style="width: 100%;border:dashed;text-align:start">';
    str +=
      '<thead ><th></th><th style="text-align:start">ItemName</th><th style="text-align:start">Qty</th></thead>';
    this.state.purchaseRequestItems.map((item, i) => {
      str +=
        "<tr><td>" +
        (i + 1) +
        "</td>" +
        "<td>" +
        item.itemName +
        "</td>" +
        "<td>" +
        item.qty +
        "</td></tr>";
    });
    str += "</table></html>";

    return str;
  };
  PrintMe = (invoiceHtml) => {
    var printWindow = window.open("", "_blank");
    printWindow.document.write(this.GetPurchaseRequestHTML());
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };
  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };
  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  OnCategoryChanged = (e) => {
    this.setState({ reload: false });
    this.setState({ brandFilterName: "" });
    this.setState({ searchquery: "" });
    this.setState({ categoryFilterName: e.target.value }, () => {
      this.setState({ reload: true });
    });
  };
  OnBrandChanged = (e) => {
    this.setState({ reload: false });
    this.setState({ categoryFilterName: "" });
    this.setState({ searchquery: "" });
    this.setState({ brandFilterName: e.target.value }, () => {
      this.setState({ reload: true });
    });
  };
  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ categoryFilterName: "" });
    this.setState({ brandFilterName: "" });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ inventoryFiltered: this.props.prods }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.props.prods, {
      keys: ["name"],
      threshold: 0.1,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ inventoryFiltered: finalResult.slice(0, 25) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ inventoryFiltered: [] });
    }
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetPurchaseRequestById = (Id) => {
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetPurchaseRequestById/" + Id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          // if (data.purchaseRequestItems != undefined) {
          //   data.purchaseRequestItems =
          //     data.purchaseRequestItems.sort((a, b) =>
          //       a.seqNo > b.seqNo ? 1 : -1
          //     );
          // }
          this.setState({ purchaseRequest: data }, () => {
            this.setState({
              purchaseRequestItems: data.purchaseRequestItems,
            });
            this.GetSeletedSuppliers();
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  AddOrUpdatePurchaseRequestInDB = () => {
    this.ShowLoadSpinner();

    var tmpPurchaseRequest = this.state.purchaseRequest;
    tmpPurchaseRequest.storeId = this.context.storeId;

    tmpPurchaseRequest.purchaseRequestItems = this.state.purchaseRequestItems;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpPurchaseRequest),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdatePurchaseReuest/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This PurchaseRequest is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdatePurchaseRequestInDB();
  };

  handleSelectionChange = (e, index) => {
    //const { name, value } = e.target;
    var name = "fileName";
    const list = [...this.state.purchaseRequestItems];
    list[index][name] = e.value;
    this.setState({ purchaseRequestItems: list });
  };

  UpdatePurchaseItem = (item, index) => {
    //this.state.purchaseRequestItems[index].sku = item.sku;
    this.state.purchaseRequestItems[index].itemName = item.itemName;
    // this.state.purchaseRequestItems[index].rate = item.rate;
    this.state.purchaseRequestItems[index].qty = item.qty;
    this.state.purchaseRequestItems[index].weight = item.weight;
    this.state.purchaseRequestItems[index].unit = item.unit;
    // this.state.purchaseRequestItems[index].tax = item.tax;
    this.setState(
      {
        purchaseRequestItems: this.state.purchaseRequestItems,
      },
      () => {
        this.setState({ editModalShow: false }, () => {});
      }
    );
  };

  AddPurchaseItem = (item) => {
    this.setState(
      {
        purchaseRequestItems: [
          ...this.state.purchaseRequestItems,
          {
            //sku: item.sku,
            itemName: item.itemName,
            // rate: item.rate,
            qty: item.qty,
            // weight: item.weight,
            // unit: item.unit,
            // discount: item.discount,
            // tax: item.tax,
            // seqNo: this.state.purchaseRequestItems.length + 1,
          },
        ],
      },
      () => {
        this.onHideOrderItemModal();
      }
    );
  };
  AddPurchaseItem1 = (item) => {
    this.setState(
      {
        purchaseRequestItems: [
          ...this.state.purchaseRequestItems,
          {
            itemName: item.name,
            qty: 1,
            weight: item.weight,
            unit: item.unit,
          },
        ],
      },
      () => {
        this.onHideOrderItemModal();
      }
    );
  };

  handleRemoveClick = (indexno) => {
    const list = [...this.state.purchaseRequestItems];
    list.splice(indexno, 1);
    this.setState({ purchaseRequestItems: list }, () => {});
  };
  onHideOrderItemModal = () => {
    this.setState({ showOrderItemModal: false });
    this.setState({ editModalShow: false }, () => {});
  };
  handleAddItems = (e) => {
    this.setState({ showOrderItemModal: true });
  };

  OnEditButtonClick = (e, i) => {
    this.setState({ editPurchaseItemRow: i }, () => {
      this.setState({ editModalShow: true }, () => {});
    });
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      // this.props.OnUpdated();
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "name":
        this.state.purchaseRequest.name = value;
        break;
      case "purchased":
        this.state.purchaseRequest.purchased = value;
        break;
      case "description":
        this.state.purchaseRequest.description = value;
        break;
    }
    this.setState({ purchaseRequest: this.state.purchaseRequest });
  };
  handlesInputChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "qty":
        this.state.purchaseRequestItems[i].qty = value;
        break;
      case "weight":
        this.state.purchaseRequestItems[i].weight = value;
        break;
      case "unit":
        this.state.purchaseRequestItems[i].unit = value;
        break;
    }
    this.setState({
      purchaseRequestItems: this.state.purchaseRequestItems,
    });
  };

  GetPricePerKgOfId = (row) => {
    var result = this.props.prods.filter((f) => f.name == row.itemName);
    if (result.length > 0) {
      if (result[0].unit == "gram" || result[0].unit == "ml") {
        var oneGram = result[0].price / result[0].weight;
        var oneKg = oneGram * 1000;
        return oneKg;
      } else if (result[0].unit == "kg" || result[0].unit == "liter") {
        var oneKg = result[0].price / result[0].weight;
        return oneKg;
      } else return 0;
    } else {
      return 0;
    }
  };
  GetPriceOfIngredient = (row) => {
    var result = this.props.prods.filter((f) => f.name == row.itemName);
    if (result.length > 0) {
      return result[0].mrp;
    } else return 0;
  };
  GetTotalEstimation = (row) => {
    return this.state.purchaseRequestItems.reduce(
      (a, v) => (a = a + this.GetPriceOfIngredient(v) * Number(v.qty)),
      0
    );
  };
  GetSuppliersForSelect = () => {
    var arr = [];
    this.props.suppliers.map((m) => {
      if (m.emailId) {
        arr.push({
          value: m.emailId,
          label: m.name + ", " + m.emailId + ", " + m.address + ", " + m.city,
        });
      }
    });
    // return arr;
    this.setState({ suppliersForSelect: arr });
  };

  GetSeletedSuppliers = () => {
    // var suppliers = this.GetSuppliersForSelect();
    var selectedSuppliers = [];
    this.state.suppliersForSelect.map((m) => {
      if (
        this.state.purchaseRequest.suppliers &&
        this.state.purchaseRequest.suppliers.includes(m.value)
      ) {
        var t = selectedSuppliers.filter((f) => f.value == m.value);
        if (t.length == 0) {
          selectedSuppliers.push(m);
        }
      }
    });
    this.setState({ selectedSuppliers: selectedSuppliers }, () => {
      this.setState({ refreshSelectedSuppliers: true }, () => {
        this.setState({ refreshSelectedSuppliers: false });
      });
    });

    // return selectedSuppliers;
  };

  componentDidMount() {
    this.setState({
      inventoryFiltered: this.props.prods,
    });
    this.GetSuppliersForSelect();

    if (this.props.Type == "Edit") {
      this.GetPurchaseRequestById(this.props.purchaseRequest.id);
    }
    if (document.getElementById("name") != null)
      document.getElementById("name").focus();

    document.addEventListener("keydown", (event) => {
      if (event.key == "F8") {
        this.handleAddItems(event);
      }
    });
  }

  componentDidUpdate = () => {};

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          keyboard={false}
          dialogClassName="order-summary-mobile"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Purchase Request"
                  : "Add Purchase Request"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className=" ">
              {/* {this.state.showOrderItemModal == true ? (
                <>
                  <AddOrEditPurchaseRquestItem
                    onHide={this.onHideOrderItemModal}
                    AddPurchaseItem={this.AddPurchaseItem}
                    Type="Add"
                  ></AddOrEditPurchaseRquestItem>
                </>
              ) : (
                <></>
              )} */}

              {window.innerWidth > 1200 ? (
                <>
                  <div
                    className="fixedTableHightOrder"
                    style={{
                      height: window.innerHeight - 200 + "px",
                    }}
                  >
                    <Row>
                      <Col>
                        <Row
                          className="m-0 p-0 bg-light border-0"
                          style={{ fontSize: "10pt" }}
                        >
                          <Col md={3}>
                            <Form.Group as={Row} className="mb-3 " controlId="">
                              <Form.Label column sm="3">
                                PR Date
                              </Form.Label>
                              <Col sm="9">
                                <DatePicker
                                  format="dd-MMM-yy"
                                  onChange={(e) => {
                                    var t = this.state.purchaseRequest;
                                    t.created = e;
                                    this.setState(
                                      { purchaseRequest: t },
                                      () => {}
                                    );
                                  }}
                                  value={this.state.purchaseRequest.created}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group as={Row} className="mb-3 " controlId="">
                              <Form.Label column sm="5">
                                Request Number/Title
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control
                                  type="text"
                                  autoFocus
                                  id="name"
                                  name="name"
                                  maxlength="99"
                                  value={this.state.purchaseRequest.name}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Form.Group as={Row} className="mb-3 " controlId="">
                              <Form.Label column sm="3">
                                Status
                              </Form.Label>
                              <Col sm="9">
                                <Form.Select
                                  type="text"
                                  name="purchased"
                                  value={this.state.purchaseRequest.purchased}
                                  required
                                  className="text-start"
                                  placeholder=""
                                  onChange={(e) => this.handleInputChange(e)}
                                >
                                  <option value={true}>Purchased</option>
                                  <option value={false}>New Request</option>
                                </Form.Select>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group as={Row} className=" " controlId="">
                              <Form.Label column sm="3">
                                Desc
                              </Form.Label>
                              <Col sm="9">
                                <Form.Control
                                  type="text"
                                  autoFocus
                                  id="description"
                                  name="description"
                                  maxlength="99"
                                  value={this.state.purchaseRequest.description}
                                  // required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row
                          className="my-3 p-0 bg-light border-0"
                          style={{ fontSize: "10pt" }}
                        >
                          <Col md={12}>
                            Suppliers:
                            {this.state.refreshSelectedSuppliers == false ? (
                              <>
                                <Select
                                  id="a1"
                                  closeMenuOnSelect={false}
                                  components={makeAnimated}
                                  isMulti
                                  options={this.state.suppliersForSelect}
                                  defaultValue={
                                    this.state.selectedSuppliers.length > 0
                                      ? this.state.selectedSuppliers
                                      : null
                                  }
                                  onChange={(e) => {
                                    this.state.purchaseRequest.suppliers = "";
                                    e.map((m) => {
                                      this.state.purchaseRequest.suppliers +=
                                        m.value + ";";
                                    });
                                    this.setState({
                                      purchaseRequest:
                                        this.state.purchaseRequest,
                                    });
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                        {/* <br />
                    {window.innerWidth > 1200 ? (
                      <></>
                    ) : (
                      <>
                        <Row>
                          <Col className="text-end">
                            <Button
                              variant="primary"
                              classname="px-2"
                              id="addbutton"
                              onClick={(e) => this.handleAddItems(e)}
                            >
                              + Add
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )} */}
                      </Col>
                    </Row>
                    <Row
                      className="m-0 p-0 bg-white "
                      style={{ fontSize: "9pt" }}
                    >
                      {window.innerWidth > 1200 ? (
                        <>
                          <Col
                            md={5}
                            className="bg-seondary border border-dark"
                          >
                            <Row className="text-center text-secondary ">
                              <h4>
                                <b> Inventory</b>
                              </h4>
                              <div className="p-0">
                                <Row>
                                  <Col>
                                    <Stack
                                      direction="horizontal"
                                      gap={3}
                                      className=""
                                    >
                                      <b>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          fill="currentColor"
                                          class="bi bi-search"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                      </b>
                                      <FormControl
                                        type="search"
                                        placeholder="Search  "
                                        className="text-primary fw-bold bg-white "
                                        aria-label="Search"
                                        onChange={(e) =>
                                          this.onSearchChange(e.target.value)
                                        }
                                      />
                                    </Stack>
                                  </Col>
                                  <Row></Row>
                                  <Col>
                                    <InputGroup className="">
                                      <InputGroup.Text id="basic-addon1">
                                        Category
                                      </InputGroup.Text>
                                      <Form.Select
                                        value={this.state.categoryFilterName}
                                        onChange={(e) => {
                                          this.OnCategoryChanged(e);
                                        }}
                                      >
                                        <option value=""></option>
                                        {this.props.category.map((m) => (
                                          <>
                                            <option value={m.id}>
                                              {m.name}
                                            </option>
                                          </>
                                        ))}
                                      </Form.Select>
                                    </InputGroup>
                                  </Col>
                                  <Col>
                                    <InputGroup className="">
                                      <InputGroup.Text id="basic-addon1">
                                        Brand
                                      </InputGroup.Text>
                                      <Form.Select
                                        value={this.state.brandFilterName}
                                        onChange={(e) => {
                                          this.OnBrandChanged(e);
                                        }}
                                      >
                                        <option value=""></option>
                                        {this.context.brands.map((m) => (
                                          <>
                                            <option value={m.id}>
                                              {m.brandName}
                                            </option>
                                          </>
                                        ))}
                                      </Form.Select>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </div>
                            </Row>

                            <Row className="text-center py-3 ">
                              <Col className="text-secondary ">
                                {/* <div className=""> */}
                                <div
                                  className="fixedTableHightOrder m-0 p-0 ordertablecustom "
                                  style={{
                                    height:
                                      window.innerHeight -
                                      (window.innerWidth > 1200 ? 165 : 250) +
                                      "px",
                                  }}
                                >
                                  <Table
                                    border-1
                                    className="bg-white  w-100 "
                                    width="50%"
                                  >
                                    <thead className="border-5 ">
                                      <tr className="text-center text-dark  border ">
                                        <th className="text-start " width="40%">
                                          Product Name
                                        </th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">InStk</th>
                                        <th className="text-end "></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-primary border-5  ">
                                      {this.state.inventoryFiltered &&
                                        this.state.inventoryFiltered
                                          .filter((f) => {
                                            if (
                                              this.state.categoryFilterName !=
                                                "" &&
                                              this.state.categoryFilterName ==
                                                f.subCategory.mainCategoryId
                                            ) {
                                              return true;
                                            } else if (
                                              this.state.brandFilterName !=
                                                "" &&
                                              this.state.brandFilterName ==
                                                f.brandId
                                            ) {
                                              return true;
                                            } else if (
                                              this.state.categoryFilterName ==
                                                "" &&
                                              this.state.brandFilterName == ""
                                            ) {
                                              return true;
                                            } else {
                                              return false;
                                            }
                                          })
                                          .sort((a, b) =>
                                            a.name.toUpperCase() >
                                            b.name.toUpperCase()
                                              ? 1
                                              : -1
                                          )
                                          .map((prod, i) => (
                                            <>
                                              <tr
                                                className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                                style={{ fontSize: "12px" }}
                                              >
                                                <td width="60%">{prod.name}</td>
                                                <td
                                                  width="10%"
                                                  className="text-center"
                                                >
                                                  {Number(
                                                    prod.mrp
                                                  ).toLocaleString(
                                                    this.context.storeSettings
                                                      .defaultLocale,
                                                    {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 1,
                                                      style: "currency",
                                                      currency:
                                                        this.context
                                                          .storeSettings
                                                          .defaultCurrency,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  width="10%"
                                                  className="text-center"
                                                >
                                                  {prod.inStockQty}{" "}
                                                </td>
                                                <td
                                                  width="20%"
                                                  className="text-end "
                                                >
                                                  {this.state
                                                    .purchaseRequestItems &&
                                                  this.state.purchaseRequestItems.filter(
                                                    (p) =>
                                                      p.itemName == prod.name
                                                  ).length > 0 ? (
                                                    <Button
                                                      variant="secondary"
                                                      disabled
                                                    >
                                                      ADDED
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      onClick={(e) => {
                                                        this.AddPurchaseItem1(
                                                          prod
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      Add
                                                    </Button>
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}

                      <Col
                        md={window.innerWidth > 1200 ? 7 : 12}
                        className="bg-seondary border border-dark"
                      >
                        <Row>
                          <Col md={7} className="text-end">
                            <h4>
                              <b>Required Items</b>
                            </h4>
                          </Col>
                          <Col md={5}>
                            <Button onClick={this.PrintMe}>
                              <BsFillPrinterFill size={20}></BsFillPrinterFill>
                              &nbsp;&nbsp;Print
                            </Button>
                            {/* <Button onClick={this.PrintMe}>
                              <BsFillPrinterFill size={20}></BsFillPrinterFill>
                              &nbsp;&nbsp;Send Request to Suppliers for
                              Quotation
                            </Button> */}
                          </Col>
                        </Row>
                        <div>
                          <Row className="m-0 p-0 bg-light border">
                            <Col className="m-0 p-0">
                              <Table
                                bordered
                                size="sm"
                                className="p-0 m-0 text-start"
                              >
                                <thead className="bg-secondary text-white">
                                  <tr size="lg" className="text-center">
                                    <td width="5%"></td>
                                    <td width="40%">Item Name</td>

                                    <td width="25%">Qty</td>
                                    <td width="10%">
                                      Est
                                      <br />
                                      {Number(
                                        this.GetTotalEstimation()
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </td>

                                    <td width="5%" className="text-center"></td>
                                  </tr>
                                </thead>
                                <tbody size="lg">
                                  {this.state.purchaseRequestItems != undefined
                                    ? this.state.purchaseRequestItems
                                        //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                        .map((row, i) => (
                                          <>
                                            <tr
                                              height="30pt"
                                              className="align-middle text-center"
                                            >
                                              <td className="text-center">
                                                {i + 1}
                                              </td>
                                              <td className="text-start">
                                                {row.itemName} <br />
                                                Mrp:{" "}
                                                {Number(
                                                  this.GetPriceOfIngredient(row)
                                                ).toLocaleString(
                                                  this.context.storeSettings
                                                    .defaultLocale,
                                                  {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1,
                                                    style: "currency",
                                                    currency:
                                                      this.context.storeSettings
                                                        .defaultCurrency,
                                                  }
                                                )}
                                              </td>

                                              <td>
                                                <Form.Control
                                                  type="number"
                                                  name="qty"
                                                  value={row.qty}
                                                  required
                                                  placeholder=""
                                                  min={0}
                                                  align="center"
                                                  className="text-center  "
                                                  autoComplete="off"
                                                  onChange={(e) =>
                                                    this.handlesInputChange(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </td>

                                              <td className="text-end">
                                                {Number(
                                                  this.GetPriceOfIngredient(
                                                    row
                                                  ) * row.qty
                                                ).toLocaleString(
                                                  this.context.storeSettings
                                                    .defaultLocale,
                                                  {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1,
                                                    style: "currency",
                                                    currency:
                                                      this.context.storeSettings
                                                        .defaultCurrency,
                                                  }
                                                )}
                                              </td>
                                              <td className="text-center">
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip>Delete!</Tooltip>
                                                  }
                                                >
                                                  <Button
                                                    variant="outline-danger"
                                                    onClick={(e) => {
                                                      this.handleRemoveClick(i);
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      class="bi bi-trash"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                      <path
                                                        fill-rule="evenodd"
                                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                      />
                                                    </svg>
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                            </tr>
                                          </>
                                        ))
                                    : ""}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    {window.innerWidth > 1200 ? (
                      ""
                    ) : (
                      <>
                        <br />
                        <Row>
                          <Col
                            md={12}
                            className="bg-seondary border border-dark"
                          >
                            <Row className="text-center text-secondary ">
                              <h4>
                                <b> Inventory</b>
                              </h4>
                              <div className="p-3">
                                <Row>
                                  <Col>
                                    <Stack
                                      direction="horizontal"
                                      gap={3}
                                      className=""
                                    >
                                      <b>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          fill="currentColor"
                                          class="bi bi-search"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                      </b>
                                      <FormControl
                                        type="search"
                                        placeholder="Search  "
                                        className="text-primary fw-bold bg-white "
                                        aria-label="Search"
                                        onChange={(e) =>
                                          this.onSearchChange(e.target.value)
                                        }
                                      />
                                    </Stack>
                                  </Col>
                                  <Col>
                                    <InputGroup className="">
                                      <InputGroup.Text id="basic-addon1">
                                        Category
                                      </InputGroup.Text>
                                      <Form.Select
                                        value={this.state.categoryFilterName}
                                        onChange={(e) => {
                                          this.OnCategoryChanged(e);
                                        }}
                                      >
                                        <option value=""></option>
                                        {this.props.category.map((m) => (
                                          <>
                                            <option value={m.id}>
                                              {m.name}
                                            </option>
                                          </>
                                        ))}
                                      </Form.Select>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </div>
                            </Row>
                            <Row className="text-center py-3 ">
                              <Col className="text-secondary ">
                                {/* <div className=""> */}
                                <div
                                  className="fixedTableHightOrder m-0 p-0 ordertablecustom "
                                  style={{
                                    height: "500px",
                                  }}
                                >
                                  <Table
                                    border-1
                                    className="bg-white  w-100 "
                                    width="50%"
                                  >
                                    <thead className="border-5 ">
                                      <tr className="text-center text-dark  border ">
                                        <th className="text-start " width="50%">
                                          Product Name
                                        </th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">InStk </th>
                                        <th className="text-end " width="50%">
                                          Add Product
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-primary border-5  ">
                                      {this.state.inventoryFiltered &&
                                        this.state.inventoryFiltered
                                          .filter((f) => {
                                            if (
                                              this.state.categoryFilterName !=
                                                "" &&
                                              this.state.categoryFilterName ==
                                                f.subCategory.mainCategoryId
                                            ) {
                                              return true;
                                            } else if (
                                              this.state.categoryFilterName ==
                                              ""
                                            ) {
                                              return true;
                                            } else {
                                              return false;
                                            }
                                          })
                                          .sort((a, b) =>
                                            a.name.toUpperCase() >
                                            b.name.toUpperCase()
                                              ? 1
                                              : -1
                                          )
                                          .map((prod, i) => (
                                            <>
                                              <tr
                                                className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                                style={{ fontSize: "12px" }}
                                              >
                                                <td width="60%">{prod.name}</td>
                                                <td
                                                  width="10%"
                                                  className="text-center"
                                                >
                                                  {Number(
                                                    prod.mrp
                                                  ).toLocaleString(
                                                    this.context.storeSettings
                                                      .defaultLocale,
                                                    {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 1,
                                                      style: "currency",
                                                      currency:
                                                        this.context
                                                          .storeSettings
                                                          .defaultCurrency,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  width="10%"
                                                  className="text-center"
                                                >
                                                  {prod.inStockQty}{" "}
                                                </td>
                                                <td
                                                  width="20%"
                                                  className="text-end "
                                                >
                                                  {this.state
                                                    .purchaseRequestItems &&
                                                  this.state.purchaseRequestItems.filter(
                                                    (p) =>
                                                      p.itemName == prod.name
                                                  ).length > 0 ? (
                                                    <Button
                                                      variant="secondary"
                                                      disabled
                                                    >
                                                      ADDED
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      onClick={(e) => {
                                                        this.AddPurchaseItem1(
                                                          prod
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      Add &nbsp;-->
                                                    </Button>
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Row
                    className=" bg-light border"
                    style={{ fontSize: "12pt" }}
                  >
                    <Col md={3}>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          PR Date
                        </Form.Label>
                        <Col sm="9">
                          <DatePicker
                            format="dd-MMM-yy"
                            onChange={(e) => {
                              var t = this.state.purchaseRequest;
                              t.created = e;
                              this.setState({ purchaseRequest: t }, () => {});
                            }}
                            value={this.state.purchaseRequest.created}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          Request Number/Title
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            autoFocus
                            id="name"
                            name="name"
                            maxlength="99"
                            value={this.state.purchaseRequest.name}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          Status
                        </Form.Label>
                        <Col sm="9">
                          <Form.Select
                            type="text"
                            name="purchased"
                            value={this.state.purchaseRequest.purchased}
                            required
                            className="text-start"
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value={true}>Purchased</option>
                            <option value={false}>New Request</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group as={Row} className=" " controlId="">
                        <Form.Label column sm="3">
                          Desc
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            autoFocus
                            id="description"
                            name="description"
                            maxlength="99"
                            value={this.state.purchaseRequest.description}
                            // required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />

                  <Row className=" bg-white border" style={{ fontSize: "9pt" }}>
                    <Row>
                      <Col xs={10} className="text-start">
                        <h4>
                          <b>Required Items</b>
                        </h4>
                      </Col>
                      <Col xs={2}>
                        <Button onClick={this.PrintMe}>
                          <BsFillPrinterFill size={16}></BsFillPrinterFill>
                        </Button>
                      </Col>
                    </Row>
                    <div>
                      <Row className="m-0 p-0 bg-light border">
                        <Col className="m-0 p-0">
                          <Table
                            bordered
                            size="sm"
                            className="p-0 m-0 text-start"
                          >
                            <thead className="bg-secondary text-white">
                              <tr size="lg" className="text-center">
                                <td width="5%"></td>
                                <td width="40%">Item Name</td>

                                <td width="25%">Qty</td>
                                <td width="10%">
                                  Est
                                  <br />
                                  {Number(
                                    this.GetTotalEstimation()
                                  ).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </td>

                                <td width="5%" className="text-center"></td>
                              </tr>
                            </thead>
                            <tbody size="lg">
                              {this.state.purchaseRequestItems != undefined
                                ? this.state.purchaseRequestItems
                                    //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                    .map((row, i) => (
                                      <>
                                        <tr
                                          height="30pt"
                                          className="align-middle text-center"
                                        >
                                          <td className="text-center">
                                            {i + 1}
                                          </td>
                                          <td className="text-start">
                                            {row.itemName} <br />
                                            Mrp:{" "}
                                            {Number(
                                              this.GetPriceOfIngredient(row)
                                            ).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </td>

                                          <td>
                                            <Form.Control
                                              type="number"
                                              name="qty"
                                              value={row.qty}
                                              required
                                              placeholder=""
                                              min={0}
                                              align="center"
                                              className="text-center  "
                                              autoComplete="off"
                                              onChange={(e) =>
                                                this.handlesInputChange(e, i)
                                              }
                                            />
                                          </td>

                                          <td className="text-end">
                                            {Number(
                                              this.GetPriceOfIngredient(row) *
                                                row.qty
                                            ).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </td>
                                          <td className="text-center">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>Delete!</Tooltip>
                                              }
                                            >
                                              <Button
                                                variant="outline-danger"
                                                onClick={(e) => {
                                                  this.handleRemoveClick(i);
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-trash"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                  />
                                                </svg>
                                              </Button>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                : ""}
                            </tbody>
                          </Table>
                          <br />
                        </Col>
                      </Row>
                    </div>
                    <br />
                  </Row>

                  <br />
                  <Row>
                    <Col md={12} className="bg-seondary border border-dark">
                      <Row className="text-center text-secondary ">
                        <h4>
                          <b> Inventory</b>
                        </h4>
                        <div className="p-3">
                          <Row className="py-2">
                            <Col>
                              <Stack
                                direction="horizontal"
                                gap={3}
                                className=""
                              >
                                <b>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-search"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                  </svg>
                                </b>
                                <FormControl
                                  type="search"
                                  placeholder="Search  "
                                  className="text-primary fw-bold bg-white "
                                  aria-label="Search"
                                  onChange={(e) =>
                                    this.onSearchChange(e.target.value)
                                  }
                                />
                              </Stack>
                            </Col>
                          </Row>
                          <Row className="py-1">
                            <Col>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  Category
                                </InputGroup.Text>
                                <Form.Select
                                  value={this.state.categoryFilterName}
                                  onChange={(e) => {
                                    this.OnCategoryChanged(e);
                                  }}
                                >
                                  <option value=""></option>
                                  {this.props.category.map((m) => (
                                    <>
                                      <option value={m.id}>{m.name}</option>
                                    </>
                                  ))}
                                </Form.Select>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="py-1">
                            <Col>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  Brand
                                </InputGroup.Text>
                                <Form.Select
                                  value={this.state.brandFilterName}
                                  onChange={(e) => {
                                    this.OnBrandChanged(e);
                                  }}
                                >
                                  <option value=""></option>
                                  {this.context.brands.map((m) => (
                                    <>
                                      <option value={m.id}>
                                        {m.brandName}
                                      </option>
                                    </>
                                  ))}
                                </Form.Select>
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                      <Row className="text-center py-3 ">
                        <Col className="text-secondary ">
                          {/* <div className=""> */}
                          <div
                            className="fixedTableHightOrder m-0 p-0 ordertablecustom "
                            style={{
                              height: "300px",
                            }}
                          >
                            <Table
                              border-1
                              className="bg-white  w-100 "
                              width="50%"
                            >
                              <thead className="border-5 ">
                                <tr className="text-center text-dark  border ">
                                  <th className="text-start " width="50%">
                                    Product Name
                                  </th>
                                  <th className="text-center">Price</th>
                                  <th className="text-center">InStk</th>
                                  <th className="text-end " width="50%"></th>
                                </tr>
                              </thead>
                              <tbody className="text-primary border-5  ">
                                {this.state.inventoryFiltered &&
                                  this.state.inventoryFiltered
                                    .filter((f) => {
                                      if (
                                        this.state.categoryFilterName != "" &&
                                        this.state.categoryFilterName ==
                                          f.subCategory.mainCategoryId
                                      ) {
                                        return true;
                                      } else if (
                                        this.state.brandFilterName != "" &&
                                        this.state.brandFilterName == f.brandId
                                      ) {
                                        return true;
                                      } else if (
                                        this.state.categoryFilterName == "" &&
                                        this.state.brandFilterName == ""
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    })
                                    .sort((a, b) =>
                                      a.name.toUpperCase() >
                                      b.name.toUpperCase()
                                        ? 1
                                        : -1
                                    )
                                    .map((prod, i) => (
                                      <>
                                        <tr
                                          className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                          style={{ fontSize: "12px" }}
                                        >
                                          <td width="60%">{prod.name}</td>
                                          <td
                                            width="10%"
                                            className="text-center"
                                          >
                                            {Number(prod.mrp).toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 1,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </td>
                                          <td
                                            width="10%"
                                            className="text-center"
                                          >
                                            {prod.inStockQty}{" "}
                                          </td>
                                          <td width="20%" className="text-end ">
                                            {this.state.purchaseRequestItems &&
                                            this.state.purchaseRequestItems.filter(
                                              (p) => p.itemName == prod.name
                                            ).length > 0 ? (
                                              <Button
                                                variant="secondary"
                                                disabled
                                              >
                                                ADDED
                                              </Button>
                                            ) : (
                                              <Button
                                                onClick={(e) => {
                                                  this.AddPurchaseItem1(prod);
                                                }}
                                              >
                                                {" "}
                                                Add
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>
              <Form.Group>
                <Button variant="primary" size="lg" disabled>
                  &nbsp;&nbsp;Save & Send Request to Suppliers for Quotation
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DatePicker from "react-date-picker";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import Fuse from "fuse.js";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  Stack,
  FormControl,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class EmployeeTimesheet extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      filteredEmployees: [],
      departments: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
    };
  }

  GetEmployees = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetEmployees/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ employees: data }, () => {
            this.setState({ filteredEmployees: data });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  GetDepartments = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetDepartments/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ departments: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ employees: [] }, () => {
      this.GetEmployees();
      this.GetDepartments();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ filteredEmployees: this.state.employees }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.filteredEmployees, {
      keys: ["firstName", "lastName", "empNo"],
      threshold: 0.6,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredEmployees: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredEmployees: [] });
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.employees.length == 0) {
      this.GetEmployees();
      this.GetDepartments();
    }
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp;Employees Timesheet &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      {/* <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddEmployee()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button> */}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="border">
                <Row>
                  <Col md={3} className="border">
                    {/* <div className="text-center">
                      <h2>Employees</h2>{" "}
                    </div> */}
                    <Table className="my-0 py-3">
                      <Row className="">
                        <Col>
                          <Stack direction="horizontal" gap={3} className="">
                            <b>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </b>
                            <FormControl
                              type="search"
                              placeholder="Search... "
                              className="text-dark fw-bold bg-white "
                              aria-label="Search"
                              onChange={(e) =>
                                this.onSearchChange(e.target.value)
                              }
                            />
                          </Stack>
                        </Col>
                      </Row>
                    </Table>
                    <div className="fixedTableHightForEmployeeTimesheet py-3">
                      <Table border className="bg-white  w-100 " width="50%">
                        <tbody className="text-primary border-1  ">
                          {this.state.filteredEmployees
                            .sort((a, b) =>
                              a.firstName > b.firstName ? 1 : -1
                            )
                            .map((employee, i) =>
                              this.state.reload === true ? (
                                <>
                                  <tr
                                    className="text-start align-middle text-dark bg-white mx-5 border "
                                    style={{ fontSize: "12px" }}
                                    onClick={(e) => {
                                      alert(employee.firstName);
                                    }}
                                  >
                                    <td>
                                      {employee.empNo +
                                        " - " +
                                        employee.firstName +
                                        " " +
                                        employee.lastName}
                                    </td>
                                  </tr>
                                </>
                              ) : null
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col md={9} className="border">
                    <div className="p-5 m-5">
                      <Row className="text-center bg-light">
                        <Col>Prev </Col>
                        <Col>
                          Month of&nbsp;&nbsp;&nbsp;
                          <DatePicker
                            format="MMM-yy"
                            onChange={(e) => {
                              // var t = this.state.employee;
                              // t.dob = e;
                              // this.setState({ employee: t }, () => {});
                            }}
                            required
                            value={new Date()}
                          />
                        </Col>
                        <Col>Next </Col>
                      </Row>
                      <br></br>
                      <br></br>
                      <Table className="text-center border">
                        <thead
                          className="text-center bg-light"
                          style={{ fontSize: "18px" }}
                        >
                          <th>Date</th>
                          <th>Status</th>
                          <th>Hours</th>
                          <th></th>
                        </thead>
                        <tbody className="border-1  ">
                          <tr>
                            <td>1-Jan-2024</td>
                            <td>Present</td>
                            <td>8</td>
                            <td>
                              <Button>Update</Button>
                            </td>
                          </tr>
                          <tr>
                            <td>2-Jan-2024</td>
                            <td>Present</td>
                            <td>8</td>
                            <td>
                              <Button>Update</Button>
                            </td>
                          </tr>
                          <tr>
                            <td>3-Jan-2024</td>
                            <td>Present</td>
                            <td>8</td>
                            <td>
                              <Button>Update</Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

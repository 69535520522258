import React, { Component } from "react";
import CartContext from "./CartContext";
import { BiSave, BiRefresh } from "react-icons/bi";
import { BsFillPencilFill, BsFillPrinterFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { SideMenu } from "./SideMenu";
import Fuse from "fuse.js";
import Select from "react-select";

import {
  OverlayTrigger,
  Tooltip,
  Stack,
  FormControl,
  InputGroup,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";
import { TbTower } from "react-icons/tb";

export class Recipes extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      recipe: { productId: "", description: "Step1: " },
      recipeItems: [],
      selectedProductCategoryId: "",
      reload: true,
      prodsFiltered: [],
      ingredientsinventory: [],
      ingredientNames: [],
      firstLoad: false,
      selectedProd: "",
    };
  }
  LoadIngredientsName = () => {
    {
      var pd = [];
      this.state.ingredientsinventory.map((r) =>
        pd.push({
          value: r.id,
          label: r.name,
        })
      );
      this.setState({ ingredientNames: pd });
    }
  };
  OnCategoryChanged = (e) => {
    this.setState({ reload: false });
    this.setState({ selectedProductCategoryId: e.target.value }, () => {
      this.setState({ reload: true });
    });
  };
  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ prodsFiltered: this.context.prods }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.context.prods, {
      keys: ["name"],
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ prodsFiltered: finalResult.slice(0, 25) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ prodsFiltered: [] });
    }
  };

  GetIngredientsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ ingredientsinventory: data }, () => {
            this.LoadIngredientsName();
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetRecipe = (Id) => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetRecipe/" + Id)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          if (data != undefined) {
            this.setState({ recipe: data }, () => {});
            if (data.recipeIngredients != undefined)
              this.setState({ recipeItems: data.recipeIngredients }, () => {});
          }
        }
      })
      .catch((error) => {
        this.setState({ recipe: { productId: "", description: "Step1: " } });
      });
  };
  OnSelectProduct = (e, prod) => {
    this.setState({ selectedProd: prod }, () => {
      this.setState({ recipeItems: [] }, () => {
        this.GetRecipe(this.state.selectedProd.id);
      });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdatePurchaseRequestInDB();
  };

  AddRecipe = () => {
    this.setState(
      {
        recipeItems: [
          ...this.state.recipeItems,
          {
            ingredientId: "",
            // productId: this.state.selectedProd.id,
            qty: 1,
          },
        ],
      },
      () => {}
    );
  };

  UpdateRecipe = (item, index) => {
    this.state.recipeItems[index].ingredientId = item.ingredientId;
    //this.state.recipeItems[index].productId = item.productId;
    this.state.recipeItems[index].qty = item.qty;
    this.setState(
      {
        recipeItems: this.state.recipeItems,
      },
      () => {}
    );
  };

  handleRemoveClick = (indexno) => {
    const list = [...this.state.recipeItems];
    list.splice(indexno, 1);
    this.setState({ recipeItems: list }, () => {});
  };

  handleRecipeInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "description":
        this.state.recipe.description = value.toUpperCase();
    }
    this.setState({ recipe: this.state.recipe });
  };
  handleInputChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "weight":
        this.state.recipeItems[i].weight = value;
        break;
      case "unit":
        this.state.recipeItems[i].unit = value;
        break;
    }
    this.setState({
      recipeItems: this.state.recipeItems,
    });
  };

  OnIngredientsChange = (e, i) => {
    this.state.recipeItems[i].ingredientId = e.value;
    this.setState({
      recipeItems: this.state.recipeItems,
    });
  };
  handleQtyChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "qty":
        this.state.recipeItems[i].qty = value;
        break;
    }
    this.setState({
      recipeItems: this.state.recipeItems,
    });
  };

  GetIngredientName = (row) => {
    if (
      this.state.ingredientNames.length > 0 &&
      row.ingredientId != undefined &&
      row.ingredientId != ""
    ) {
      return this.state.ingredientNames.filter(
        (f) => f.value == row.ingredientId
      )[0].label;
    } else return "";
  };
  GetPricePerKgOfId = (row) => {
    var result = this.state.ingredientsinventory.filter(
      (f) => f.id == row.ingredientId
    );
    if (result.length > 0) {
      if (result[0].unit == "gram" || result[0].unit == "ml") {
        var oneGram = result[0].price / result[0].weight;
        var oneKg = oneGram * 1000;
        return oneKg;
      } else if (
        result[0].unit == "kg" ||
        result[0].unit == "liter" ||
        result[0].unit == "piece"
      ) {
        var oneKg = result[0].price / result[0].weight;
        return oneKg;
      } else return 0;
    } else {
      return 0;
    }
  };
  GetPriceOfIngredient = (row) => {
    var result = this.state.ingredientsinventory.filter(
      (f) => f.id == row.ingredientId
    );
    if (result.length > 0) {
      var perKg = this.GetPricePerKgOfId(row);
      if (row.unit == "gram" || row.unit == "ml") {
        var r = (perKg / 1000) * row.weight;
        return r;
      } else if (
        row.unit == "kg" ||
        row.unit == "liter" ||
        row.unit == "piece"
      ) {
        var r = perKg * row.weight;
        return r;
      } else return 0;
    } else return 0;
  };
  GetTotalEstimation = (row) => {
    return this.state.recipeItems.reduce(
      (a, v) => (a = a + this.GetPriceOfIngredient(v) * Number(v.qty)),
      0
    );
  };

  OnSave = () => {
    //this.ShowLoadSpinner();
    this.state.recipe.productId = this.state.selectedProd.id;
    this.state.recipe.recipeIngredients = this.state.recipeItems;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.recipe),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateRecipe/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          //this.HideLoadSpinner();
          alert("Saved Successfully!");
          return;
        }
        //this.HideLoadSpinner();
        alert("Unable to Save. Try after sometime !");
        return;
      })
      .catch((error) => {
        //this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };
  OnRefreshButtonClick = () => {
    this.setState({ ingredientsinventory: [] }, () => {
      this.GetIngredientsInventories();
    });
  };

  GetRecipetHTML = () => {
    var str = "<html>";
    str += '<div style="width: 100%;text-align: center">';
    str +=
      "<h4><u>" +
      "Details of " +
      this.state.selectedProd.name +
      "</u>" +
      "</h4>";
    str += "<h5>";
    str += "Description: " + this.state.recipe.description + "</h5><br/>";
    str += "</div>";

    str += '<table style="width: 100%;border:dashed;text-align:center">';
    str +=
      "<thead ><th></th><th>ItemName</th><th>Weight</th><th>Unit</th><th>Qty</th></thead>";
    this.state.recipeItems.map((item, i) => {
      str +=
        "<tr><td>" +
        (i + 1) +
        "</td>" +
        "<td>" +
        this.GetIngredientName(item) +
        "</td>" +
        "<td>" +
        item.weight +
        "</td>" +
        "<td>" +
        item.unit +
        "</td>" +
        "<td>" +
        item.qty +
        "</td></tr>";
    });
    str += "</table></html>";

    return str;
  };
  PrintMe = (invoiceHtml) => {
    var printWindow = window.open("", "_blank");
    printWindow.document.write(this.GetRecipetHTML());
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };
  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };
  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.setState({
        prodsFiltered: this.context.prods,
      });
      this.GetIngredientsInventories();
    }
  }

  render() {
    return (
      <div
        className="Container-fluid p-1 "
        style={{ fontFamily: "Verdana", fontSize: "12px" }}
      >
        <Row>
          <Col className="" xs={2}>
            <SideMenu></SideMenu>
          </Col>
          <Col xs={10} className="pagebackground p-0 m-0">
            {this.state.msgboxOpen === true ? (
              <MessageBox
                open={this.state.msgboxOpen}
                title={this.state.msgboxTitle}
                message={this.state.msgboxMessage}
                callback={this.CloseMessagBoxCallback}
              />
            ) : (
              ""
            )}
            <Navbar
              expand="lg"
              className="secondarythemecolor position-sticky fixed-top  p-0 m-0"
            >
              {this.context.store.businessTypeId == 3 ? (
                <>
                  <h5>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Recipe Management with RawMaterials</b>&nbsp;
                  </h5>
                </>
              ) : (
                <>
                  <h5>
                    <b>
                      &nbsp;&nbsp;&nbsp;&nbsp;Product Manufacturing Procedures &
                      Cost Estimation
                    </b>
                    &nbsp;
                  </h5>
                </>
              )}
              <Navbar.Collapse className="justify-content-end mx-0 ">
                <Nav>
                  <ButtonToolbar className="text-end align-middle">
                    <Button
                      className="mainthemecolor"
                      size=""
                      onClick={() => this.OnRefreshButtonClick()}
                    >
                      <BiRefresh size="26"></BiRefresh>
                      Refresh
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                  </ButtonToolbar>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Row className="my-1 p-0 bg-white " style={{ fontSize: "9pt" }}>
              <Col md={3} className="bg-seondary border-0 border-dark">
                <div
                  className="fixedTableHightOrder"
                  style={{
                    height: window.innerHeight - 100 + "px",
                  }}
                >
                  <Row className="text-center text-dark bg-light">
                    <h5 className="bg-warning p-0">
                      <b>Products</b>
                    </h5>
                    <div className="p-1">
                      <Row>
                        <Col>
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              Category
                            </InputGroup.Text>
                            <Form.Select
                              value={this.state.selectedProductCategoryId}
                              onChange={(e) => {
                                this.OnCategoryChanged(e);
                              }}
                            >
                              <option value=""></option>
                              {this.context.mainCategories.map((m) => (
                                <>
                                  <option value={m.id}>{m.name}</option>
                                </>
                              ))}
                            </Form.Select>
                          </InputGroup>
                          <br />
                          <Stack direction="horizontal" gap={3} className="">
                            <b>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </b>
                            <FormControl
                              type="search"
                              placeholder="Search  "
                              className="text-primary fw-bold bg-white "
                              aria-label="Search"
                              onChange={(e) =>
                                this.onSearchChange(e.target.value)
                              }
                            />
                          </Stack>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                  <Row className="text-center py-3">
                    <Col className="text-secondary ">
                      <div className="">
                        <Table
                          striped
                          hover
                          border-1
                          className="bg-white  w-100 "
                          width="50%"
                        >
                          {/* <thead className="border-5 ">
                            <tr className="text-center text-dark  border ">
                              <th className="text-center " width="100%">
                                <h5>Product Name</h5>
                              </th>
                            </tr>
                          </thead> */}
                          <tbody className="text-primary border-5  ">
                            {this.state.prodsFiltered &&
                              this.state.prodsFiltered
                                .filter((f) => {
                                  if (
                                    this.state.selectedProductCategoryId !=
                                      "" &&
                                    this.state.selectedProductCategoryId ==
                                      f.subCategory.mainCategoryId
                                  ) {
                                    return true;
                                  } else if (
                                    this.state.selectedProductCategoryId == ""
                                  ) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                })
                                .map((prod, i) => (
                                  <>
                                    <tr
                                      className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                      style={{ fontSize: "12px" }}
                                    >
                                      <td
                                        width="100%"
                                        onClick={(e) =>
                                          this.OnSelectProduct(e, prod)
                                        }
                                      >
                                        {prod.name}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                md={9}
                className="bg-seondary "
                hidden={this.state.selectedProd == ""}
              >
                <div
                  className=" "
                  style={
                    {
                      // height: window.innerHeight - 150 + "px",
                    }
                  }
                >
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Col sm="12">
                      <Row>
                        <Col md={10}>
                          <h5>
                            <b>
                              {this.context.store.businessTypeId == 3 ? (
                                <>Product Recipe of&nbsp;&nbsp;</>
                              ) : (
                                <>
                                  Product Manufacturing Procedure of&nbsp;&nbsp;
                                </>
                              )}
                              <b className="bg-warning">
                                "&nbsp;
                                {this.state.selectedProd != "" &&
                                  this.state.selectedProd.name}
                                &nbsp;"
                              </b>
                            </b>
                          </h5>
                        </Col>
                        <Col md={2} className="text-end">
                          <Button onClick={this.PrintMe}>
                            <BsFillPrinterFill size={20}></BsFillPrinterFill>
                            &nbsp;&nbsp;Print
                          </Button>
                        </Col>
                      </Row>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={10}
                        name="description"
                        value={this.state.recipe.description}
                        required
                        maxlength="999"
                        placeholder="Enter prepration steps of this product"
                        autoComplete="off"
                        onChange={(e) => this.handleRecipeInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Navbar expand="sm" className="px-0">
                    {this.context.store.businessTypeId == 3 ? (
                      <>
                        <h5>
                          <b>RawMaterials/Ingredients & Estimation</b>
                        </h5>
                      </>
                    ) : (
                      <>
                        <h5>
                          <b>RawMaterial Requirements & Estimation</b>
                        </h5>
                      </>
                    )}

                    <Navbar.Collapse className="justify-content-end mx-0 ">
                      <Nav>
                        <ButtonToolbar className="text-end align-middle">
                          <Button
                            className="mainthemecolor"
                            onClick={() => this.AddRecipe()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="currentColor"
                              class="bi bi-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Add
                          </Button>
                          &nbsp;
                          <Button
                            className="mainthemecolor"
                            size=""
                            onClick={() => this.OnSave()}
                          >
                            <BiSave size="25" />
                            Save
                          </Button>
                        </ButtonToolbar>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  <div className="mx-3">
                    <Row className="m-0 p-0 bg-light border">
                      <Col>
                        <Table bordered size="sm" className=" text-start">
                          <thead className="bg-secondary text-white">
                            <tr size="lg" className="text-center">
                              <td width="5%"></td>
                              <td width="35%">RawMaterial Name</td>
                              <td width="10%">Weight</td>
                              <td width="10%">Unit</td>
                              <td width="10%">Qty</td>
                              <td width="10%">Price</td>
                              <td width="15%">
                                Estimation
                                <br />
                                <h5 className="text-warning">
                                  [Tot:{" "}
                                  {Number(
                                    this.GetTotalEstimation()
                                  ).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                  ]
                                </h5>
                              </td>
                              <td width="5%" className="text-center">
                                Options
                              </td>
                            </tr>
                          </thead>
                          <tbody size="lg">
                            {this.state.recipeItems != undefined
                              ? this.state.recipeItems.map((row, i) => (
                                  <>
                                    <tr
                                      height="30pt"
                                      className="align-middle text-center"
                                    >
                                      <td className="text-center">{i + 1}</td>
                                      <td className="text-start">
                                        {/* {row.ingredientId} */}
                                        <Select
                                          name="itemName"
                                          autoFocus
                                          value={{
                                            value: row.ingredientId,
                                            label: this.GetIngredientName(row),
                                          }}
                                          onChange={(e) =>
                                            this.OnIngredientsChange(e, i)
                                          }
                                          options={this.state.ingredientNames}
                                        ></Select>
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          name="weight"
                                          value={row.weight}
                                          placeholder=""
                                          min={0}
                                          align="center"
                                          className="text-center  "
                                          autoComplete="off"
                                          onChange={(e) =>
                                            this.handleInputChange(e, i)
                                          }
                                        />
                                      </td>
                                      <td className="text-start ">
                                        {/* {this.state.rawmaterialsinventory.name} */}
                                        <Form.Select
                                          type="text"
                                          name="unit"
                                          value={row.unit}
                                          placeholder=""
                                          min={0}
                                          align="center"
                                          className="text-start "
                                          autoComplete="off"
                                          onChange={(e) =>
                                            this.handleInputChange(e, i)
                                          }
                                        >
                                          <option value={""}></option>
                                          <option value={"piece"}>piece</option>
                                          <option value={"gram"}>gram</option>
                                          <option value={"kg"}>kg</option>
                                          <option value={"ml"}>ml</option>
                                          <option value={"liter"}>liter</option>
                                          <option value={"meter"}>meter</option>
                                        </Form.Select>
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          name="qty"
                                          value={row.qty}
                                          required
                                          placeholder=""
                                          min={0}
                                          align="center"
                                          className="text-center  "
                                          autoComplete="off"
                                          onChange={(e) =>
                                            this.handleQtyChange(e, i)
                                          }
                                        />
                                      </td>
                                      <td className="text-end">
                                        {Number(
                                          this.GetPriceOfIngredient(row)
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </td>
                                      <td className="text-end">
                                        {Number(
                                          this.GetPriceOfIngredient(row) *
                                            row.qty
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <OverlayTrigger
                                          overlay={<Tooltip>Delete!</Tooltip>}
                                        >
                                          <Button
                                            variant="outline-danger"
                                            onClick={(e) => {
                                              this.handleRemoveClick(i);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-trash"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                              />
                                            </svg>
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : ""}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
